@charset "utf-8";

@import "base.css";
@import "common.css";

/*####################　PageIndex　####################*/
#PageIndex #Header #Header_warp{
  /*border-bottom:5px solid #1A4381;*/
}

#PageIndex #MovieBox{
  position: relative;
  margin: 0 auto;
  /*padding-top: 56.25%;*/
  width: 100%;
}
/*#PageIndex #MovieBox iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}*/
#PageIndex #MovieBox video{
  width: 100%;
  height: auto;
}
#PageIndex #ContactBox{
  margin: 0 auto;
  background-color: #033c95;
}
#PageIndex #ContactBox a{
  display: block;
}
#PageIndex #ContactBox img{
  width: 100%;
  height: auto;
}
/*　#MainImg
-------------------------------------*/
#PageIndex #MainImg01{
  margin-bottom: 60px;
  background-color: #feef7c;
}
#PageIndex #MainImg02{
  margin-bottom: 60px;
}

#PageIndex .mainImgBox{
  text-align: center;
}
#PageIndex .mainImg{
  height: auto;
}
/*　#Main_img
-------------------------------------*/
#PageIndex #Main #Main_img p.top{
  box-sizing:border-box;
  margin:0 auto;
  font-weight:bold;
}

#PageIndex #Main #Main_img p.top span{
  color:#da2634;
  position:relative;
  top:4px;
}

#PageIndex #Main #Main_imgp.top span:first-of-type{
  background-size: 102px auto;
}

#PageIndex #Main #Main_img .catchcopy{
  margin-top:-20px;
  position:relative;
}

#PageIndex #Main #Main_img .catchcopy:after{
  content: "";  
  display: block;  
  clear: both;
}

#PageIndex #Main #Main_img .catchcopy .catch01{
  margin-top:40px;
  margin-right:-25px;
  float:left;
}

#PageIndex #Main #Main_img .catchcopy .catch01 p{
  color:#0046b1;
  font-weight:bold;
  line-height:0.8;
}

#PageIndex #Main #Main_img .catchcopy .catch01 p:first-of-type{
  letter-spacing:-0.1em;
}

#PageIndex #Main #Main_img .catchcopy .catch01 p:nth-of-type(2){
  letter-spacing:-0.2em;
}

#PageIndex #Main #Main_img .catchcopy p.catch02{
  box-sizing:border-box;
  position:absolute;
  background:url(../img/contents/top/main_hukidasi.png) no-repeat left top;
  letter-spacing:-0.05em;
  color:#003586;
  font-weight:bold;
}

#PageIndex #Main #Main_img .catchcopy p.catch03{
  color:#da2634;
  font-weight:bold;
  line-height:0.8;
}

#PageIndex #Main #Main_img .catchcopy p.catch03 span.font04{
  line-height:1;
  position:relative;
  top:3px;
}

#PageIndex #Main #Main_img .list h2{
  box-sizing:border-box;
  padding-top:1px;
  height:38px;
  background:url(../img/contents/top/main_ribbon.png) no-repeat center top;
  background-size: 206px auto;
  color:#fff;
  letter-spacing:0.03em;
  line-height:1;
  position:relative;
  z-index:2;
}

#PageIndex #Main #Main_img .list ul{
  box-sizing:border-box;
  z-index:1;
  position:absolute;
  background-color:#FFF;
  border-radius:5px;
  -moz-border-radius:5px;
  -webkit-border-radius:5px;
  border:2px solid #FBE76A;
}

#PageIndex #Main #Main_img .list ul li:before{
    margin-right: 5px;
  position: relative;
}

#PageIndex {
  #Main {
    #ContBox05 {
      margin-top: 79px;
      border-top: 1px solid #e7e7e7;
      padding: 82px 0 1px;
      .title {
        font-size: 40px;
        font-weight: bold;
        letter-spacing: 0.1em;
        line-height: 1;
        text-align: center;
        margin-bottom: 57px;
      }
      .blogButton {
        margin: 30px auto 0;
      }
    }
  }
}


@media print, screen and (min-width: 768px) {
  #PageIndex #ContactBox{
    margin-top: -4px;
    box-sizing: border-box;
    /*max-width: 1365px;*/
    padding: 50px 30px;
  }
  #PageIndex #ContactBox a{
    margin: 0 auto;
    width: 100%;
    max-width: 1306px;
  }
  #PageIndex #ContactBox a:hover{
    opacity: .7;
  }
  #PageIndex #ContactBox .pc_display{
    display: block;
  }
  #PageIndex #ContactBox .sp_display{
    display: none;
  }
/*　#MainImg
-------------------------------------*/
  #PageIndex .mainImg{
    /*width: 1000px;*/
    width: 100%;
    max-width: 1365px;
    margin: 0 auto;
  }
  #PageIndex .mainImgBox a:hover{
    opacity: .8;
  }

  #PageIndex .moreBtn {
    margin-top: -10px;
    margin-right: 2px;
    width: 316px;
    height: 57px;
    float: right;
  }

  #PageIndex .moreBtn p {
      margin-top: 3px;
      box-sizing: border-box;
      text-align: center;
      height: 52px;
      font-size: 16px;
      background-color: #fffff1;
      border-radius: 53px;
      -moz-border-radius: 53px;
      -webkit-border-radius: 53px;
      border: 4px solid #e60012;
      position: relative;
  }
  #PageIndex #Main_img .moreBtn p::before{
      content: "";
      position: absolute;
      top: -6px;
      left: -6px;
      border: 2px solid #fff;
      border-radius: 53px;
      -moz-border-radius: 53px;
      -webkit-border-radius: 53px;
      height: 52px;
      width: 315px;
  }
  #PageIndex .moreBtn p a {
    box-sizing: border-box;
    padding-left: 20px;
    padding-top: 10px;
    display: block;
    width: 100%;
    height: 100%;
    background: url(../img/contents/top/moreBtn_af.png) no-repeat right 19px center;
    color: #333333;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    }
  #PageIndex .moreBtn p a::before {
      content: "";
      background: url(../img/contents/top/moreBtn_car_03.png) no-repeat center left 30px;
      position: absolute;
      top: 0;
      bottom: 2px;
      right: -6px;
      width: 320px;
      height: 57px;
      margin: auto;
    }
/*　#ConMain01
-------------------------------------*/
  #PageIndex #Main #ConMain01{
    background: #ca2431 url(../img/contents/ConMain01_bk.png) center no-repeat;
    padding-top: 30px;
    padding-bottom: 37px;
  }
  #PageIndex #Main #ConMain01 dl{
    margin-bottom: 20px;
  }
  #PageIndex #Main #ConMain01 dl dt{
    color: #fffc00;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: -0.5px;
    margin-bottom: 8px;
  }
  #PageIndex #Main #ConMain01 dl dd{
    color: #fff;
    font-size: 56px;
    line-height: 66px;
    font-weight: bold;
    letter-spacing: -1px;
  }
  #PageIndex #Main #ConMain01 span{
    color: #ffd5d8;
    font-size: 14px;
    letter-spacing: 1px;
  }
}
@media print, screen and (max-width: 767px) {
  #PageIndex #ContactBox{
    margin-top: -7px;
    padding: 40px 15px;
  }
  #PageIndex #ContactBox .pc_display{
    display: none;
  }
  #PageIndex #ContactBox .sp_display{
    display: block;
  }
  #PageIndex .mainImg{
    width: 100%;
  }

  #PageIndex #Main #ConMain01{
    background: url(../img/contents/ConMain01_bk_sp01.png) center top 27px no-repeat;
    -webkit-background-size: 320px auto;
    background-size: 320px auto;
    background-color: #ca2431;
    padding-top: 24px;
    padding-bottom: 27px;
  }
  #PageIndex #Main #ConMain01 dl{
    margin-bottom: 10px;
  }
  #PageIndex #Main #ConMain01 dl dt{
    color: #fffc00;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    letter-spacing: 0px;
    margin-bottom: 8px;
  }
  #PageIndex #Main #ConMain01 dl dd{
    color: #fff;
    font-size: 30px;
    line-height: 35px;
    font-weight: bold;
    letter-spacing: 2px;
  }
  #PageIndex #Main #ConMain01 span{
    color: #ffd5d8;
    padding-top: 93px;
    font-size: 12px;
    letter-spacing: 1px;
    display: block;
    background: url(../img/contents/ConMain01_bk_sp02.png) top center no-repeat;
    -webkit-background-size: 203px auto;
    background-size: 203px auto;
    background-position: calc(50% + -11px) 0px
  }
}
/*　#ConMain03
-------------------------------------*/
#PageIndex #Main #ConMain03{
  border-top: 1px solid #eff0f2;
  background-color: #f7fafe;
  text-align: center;
}

#PageIndex #Main #ConMain03 h2{
  letter-spacing: 1px;
  font-weight: bold;
}

#PageIndex #Main #ConMain03 h2 span{
  color: #0046b1;
}

#PageIndex #Main #ConMain03 h2 em{
  font-weight: bold;
  color: #da2634;
}

#PageIndex #Main #ConMain03 h2 b{
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  color: #da2634;
}

#PageIndex #Main #ConMain03 iframe{
  display: block;
}

#PageIndex #Main #ConMain03 p{
  margin-bottom: 0;
  text-align: center;
  font-size: 12px;
}

#PageIndex #Main #ConMain03 p span{
  display: inline-block;
}

#PageIndex #Main #ConMain03 a{
  color: #002f76;
}

/*　#ContBox01
-------------------------------------*/
#PageIndex #Main #ContBox01 h2 span{
  color:#FFFC01;
}

/*　#ContBox02
-------------------------------------*/
#PageIndex #Main #ContBox02 p.message{
  text-align:center;
  font-weight:bold;
  line-height:1;
}

#PageIndex #Main #ContBox02 p.message span{
  color:#da2634;
}

#PageIndex #Main #ContBox02 p.message:last-of-type{
  position:relative;
  z-index:2;
  background:url(../img/contents/top/ContBox02_arrow.png) no-repeat center bottom;
}

#PageIndex #Main #ContBox02 .plan{
  margin-top:-40px;
  padding:15px;
  background:url(../img/contents/top/ContBox02_img02.png) no-repeat right top;
  background-color:#fff;
  text-align:left;
  /* box-shadow */
  box-shadow:6px 5px 3px -3px #EFEFE3;
  -moz-box-shadow:6px 5px 3px -3px #EFEFE3;
  -webkit-box-shadow:6px 5px 3px -3px #EFEFE3;
}

#PageIndex #Main #ContBox02 .plan .img .point{
  box-sizing:border-box;
  position:absolute;
  background:url(../img/contents/top/ContBox02_point.png) no-repeat right top;
  color:#fff;
  letter-spacing:0.1em;
  text-align:center;
}

#PageIndex #Main #ContBox02 .plan h4{
  margin-bottom:5px;
  color:#0046b1;
  font-weight:bold;
  letter-spacing:-0.03em;
  line-height:1.4;
}


/*　#ContBox03
-------------------------------------*/
#PageIndex #Main #ContBox03 .title:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 18px;
  margin-left: -18px;
}

#PageIndex #Main #ContBox03 .title h3 .point01{
  color:#0045B4;
}

#PageIndex #ContBox03 .title h3 .point02{
  color:#DA2534;
}

#PageIndex #Main #ContBox03 .conBox{
  padding-top:30px;
  padding-bottom:30px;
  background-color:#033C95;
}

/*　#ContBox04
-------------------------------------*/
#PageIndex #Main #ContBox04 p.title{
  box-sizing:border-box;
  text-align:center;
  background:url(../img/contents/top/sp_ContBox04_ribbon.png) no-repeat center center;
  color:#fff;
  line-height:1;
}

#PageIndex #Main #ContBox04 .subbox:first-of-type .title{
  background-color:#11B3C0;
}

#PageIndex #Main #ContBox04 .subbox:nth-of-type(2) .title{
  background-color:#BCA734;
}

#PageIndex #Main #ContBox04 .subbox .title h4{
  margin-top:-7px;
  text-align:center;
  line-height:1;
}

#PageIndex #Main #ContBox04 .subbox ul li:before{
  font-size:11px;
    margin-right: 5px;
  position: relative;
}

#PageIndex #Main #ContBox04 .subbox:first-of-type ul li:before{
  content: url("../img/contents/top/ContBox04_listicon01.png");
}

#PageIndex #Main #ContBox04 .subbox:nth-of-type(2) ul li:before{
  content: url("../img/contents/top/ContBox04_listicon02.png");
}


#PageIndex #Main #ContBox04 .maintenance ul li:before{
  font-size:11px;
  content: "・";
    margin-right: 5px;
  position: relative;
     top: 0;
}

#PageIndex #Main #ContBox04 .info p{
  display:table-cell;
  vertical-align:middle;
  padding-left:130px;
  letter-spacing:-0.03em;
}

@media print, screen and (min-width: 768px) {
  /*　#Header
  -------------------------------------*/
  /*#PageIndex #Header #Header_warp{
    box-sizing:border-box;
    height:108px;
  }*/
  
  /*　#Main_img
  -------------------------------------*/
  #PageIndex #Main #Main_img{
    background:#FFF9BB url(../img/contents/top/main_bk.jpg) no-repeat center top;
  }
  
  #PageIndex #Main #Main_img .innerBasic_box{
    box-sizing:border-box;
    margin:0 auto;
    height:500px;
    width:1000px;
  }
  #PageIndex #Main #Main_img .innerBasic_box::after {
    content: "";
    display: block;
    clear: both;
  }
  #PageIndex #Main #Main_img .main_box{
    position:relative;
    top:45px;
    left:-10px;
    width:481px;
    height:425px;
    background:url(../img/contents/top/main_txt_02.png) no-repeat center top;
    text-indent:-9999px;
  }
  
  
  /*　#ConMain02
  -------------------------------------*/
  #PageIndex #Main #ConMain02{
    box-sizing:border-box;
    height:747px;
    padding-top:30px;
    background:url(../img/contents/top/ConMain02_bk_02.png) no-repeat center top;
    position: relative;
  }
  #PageIndex #Main #ConMain02 a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5
  }
  #PageIndex #Main #ConMain02:hover {
    opacity: 0.8;
  }
  
  #PageIndex #Main #ConMain02 .innerBasic{
    position:relative;
  }
  
  #PageIndex #Main #ConMain02 p.top{
    box-sizing:border-box;
    margin-bottom:5px;
    font-weight:bold;
    letter-spacing:0.05em;
    height:105px;
    font-size:40px;
  }
  
  #PageIndex #Main #ConMain02 p.top span{
    color:#da2634;
    position:relative;
    top:4px;
    font-size:60px;
  }
  
  #PageIndex #Main #ConMain02 .point{
    box-sizing:border-box;
    position:absolute;
    top:0;
    width:180px;
    height:180px;
    background:url(../img/contents/top/ContBox01_point.png) no-repeat center top;
    text-align:center;
    font-size:22px;
    color:#0046b0;
    font-weight:bold;
    letter-spacing:-0.06em;
  }
  
  #PageIndex #Main #ConMain02 .point span{
    font-size:16px;
  }
  
  #PageIndex #Main #ConMain02 .title01{
    padding-top:45px;
    line-height:1.4;
    left:695px;
  }
  
  #PageIndex #Main #ConMain02 .title02{
    padding-top:60px;
    line-height:1;
    left:865px;
  }
  
  
  #PageIndex #Main #ConMain02 .list{
    box-sizing:border-box;
    position:absolute;
    top:390px;
    left:40px;
    width:458px;
    height:159px;
    background:url(../img/contents/top/ContBox01_hukidasi.png) no-repeat left top;
  }
  
  #PageIndex #Main #ConMain02 .list ul:after{
    content: "";  /* 新しい要素を作る */
    display: block;  /* ブロックレベル要素に */
    clear: both;
  }
  
  #PageIndex #Main #ConMain02 .list ul{
    padding:50px 0 0 49px;
  }
  
  #PageIndex #Main #ConMain02 .list ul li{
    float:left;
    font-size:18px;
    text-align:left;
    font-weight:bold;
  }
  
  #PageIndex #Main #ConMain02 .list ul li.menu01{
    width:155px;
  }
  
  #PageIndex #Main #ConMain02 .list ul li.menu02{
    width:194px;
  }
  
  #PageIndex #Main #ConMain02 .list ul li:before{
    font-size:11px;
    content: url("../img/contents/top/ContBox01_cheach.png") ;
    margin-right: 5px;
    position: relative;
     top: 0;
  }
  
  #PageIndex #Main #ConMain02 .catch{
    position:absolute;
    top:327px;
    left:500px;
    width:576px;
    height:259px;
    background:url(../img/contents/top/ContBox01_price_02.png) no-repeat left top;
    text-indent:-9999px;
  }
  #PageIndex #Main #ConMain02 .moreBtn {
    position: absolute;
    right: 0;
    left: 0;
    top: 600px;
    width: 490px;
    margin: auto;
  }
  #PageIndex #Main #ConMain02 .moreBtn p {
    box-sizing: border-box;
    margin: 0;
    text-align: center;
    width: 491px;
    height: 57px;
    font-size: 16px;
    background-color: #012358;
    border-radius: 53px;
    -moz-border-radius: 53px;
    -webkit-border-radius: 53px;
    border: 2px solid #627FA9;
    position: relative;
  }
  #PageIndex #Main #ConMain02 .moreBtn p a {
    box-sizing: border-box;
    padding-left: 34px;
    padding-top: 15px;
    display: block;
    width: 100%;
    height: 100%;
    background: url(../img/contents/top/ContBox03_icon07.png) no-repeat right center;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    font-weight: 300;
    }
  #PageIndex #Main #ConMain02 .moreBtn p a::before {
      content: "";
      background: url(../img/contents/top/moreBtn_be.png) no-repeat;
      position: absolute;
      top: 0;
      bottom: 2px;
      right: 78px;
      left: 0;
      width: 28px;
      height: 23px;
      margin: auto;
      padding-right: 140px;
    }
  #PageIndex #Main #ConMain02 .moreBtn:hover {
    opacity: 1;
  }
  
  /*　#ContBox01
  -------------------------------------*/
  #PageIndex #Main #ContBox01{
    box-sizing:border-box;
    padding-top:15px;
    height:120px;
    background:#CA2432 url(../img/contents/top/ContBox01_bk.png) no-repeat center bottom;
    color:#fff;
  }
  
  #PageIndex #Main #ContBox01 p{
    font-size:16px;
    text-align:center;
    letter-spacing:0.8em;
  }
  
  #PageIndex #Main #ContBox01 h2{
    margin-top:-15px;
    font-size:40px;
    text-align:center;
    line-height:1;
    font-weight:bold;
    letter-spacing: 0.05em;
  }
  
  #PageIndex #Main #ContBox01 h2 span.font04{
    position:relative;
    font-size:66px;
    top:3px;
  }
  
  /*　#ContBox02
  -------------------------------------*/
  #PageIndex #Main #ContBox02{
    box-sizing:border-box;
    padding-top:62px;
    height:667px;
    background:#FEFFF1 url(../img/contents/top/ContBox02_bk.png) no-repeat center bottom;
  }
  
  #PageIndex #Main #ContBox02 h3{
    margin:0 auto 30px;
    color:#755a30;
    width:500px;
    font-size:40px;
    letter-spacing:-0.03em;
    background:url(../img/contents/top/ContBox02_h3_bk.png) no-repeat 10px top;
  }
  
  #PageIndex #Main #ContBox02 p.message{
    margin-bottom:20px;
    font-size:20px;
  }
  
  #PageIndex #Main #ContBox02 p.message span{
    font-size:26px;
  }
  
  #PageIndex #Main #ContBox02 p.message:last-of-type{
    padding-bottom:80px;
  }
  
  #PageIndex #Main #ContBox02 .plan{
    box-sizing:border-box;
    margin-top:-40px;
    padding:15px 10px 0 25px;
    background:url(../img/contents/top/ContBox02_img02.png) no-repeat right top;
    background-color:#fff;
    height:237px;
    text-align:left;
    /* box-shadow */
    box-shadow:6px 5px 3px -3px #EFEFE3;
    -moz-box-shadow:6px 5px 3px -3px #EFEFE3;
    -webkit-box-shadow:6px 5px 3px -3px #EFEFE3;
  }
  
  #PageIndex #Main #ContBox02 .plan:after{
    content: "";  /* 新しい要素を作る */
    display: block;  /* ブロックレベル要素に */
    clear: both;
  }
  
  #PageIndex #Main #ContBox02 .plan .img{
    position:relative;
    width:310px;
    float:left;
  }
  
  #PageIndex #Main #ContBox02 .plan .img img{
    padding: 18px 0 0 20px;
    width: 261px;
    height: auto;
  }
  
  #PageIndex #Main #ContBox02 .plan .img .point{
    padding:20px 0 0 3px;
    top: 3px;
    left: 1px;
    width:64px;
    height:64px;
    background:url(../img/contents/top/ContBox02_point.png) no-repeat right top;
    background-size:64px auto;
    font-size:16px;
  }
  
  #PageIndex #Main #ContBox02 .plan h4{
    margin-top:31px;
    font-size:26px;
  }
  
  #PageIndex #Main #ContBox02 .plan h4 span.font04{
    font-size:34px;
    color:#DA2534;
  }
  
  #PageIndex #Main #ContBox02 .plan p.txt{
    font-size:16px;
    line-height:1.8;
    letter-spacing:-0.03em;
  }
  
  /*　#ContBox03
  -------------------------------------*/
  #PageIndex #Main #ContBox03{
    padding-top:6px;
    border-top:3px solid #D0D9E8;
  }
  
  #PageIndex #Main #ContBox03 .title{
    line-height:1;
    position: relative;
    height:83px;
  }
  
  #PageIndex #Main #ContBox03 .title h3{
    padding-left:30px;
    margin:0 auto;
    width:860px;
    background:#fff url(../img/contents/top/ContBox03_icon01.png) no-repeat left 14px;
    font-size:40px;
    font-weight:bold;
    text-align:center;
    letter-spacing:0.02em;
  }
  
  #PageIndex #Main #ContBox03 .title h3 .font04{
    font-size:66px;
  }
  
  #PageIndex #Main #ContBox03 .title h3 .font04:first-of-type{
    position:relative;
    top:3px;
  }
  
  #PageIndex #Main #ContBox03 .conBox .symbol01,
  #PageIndex #Main #ContBox03 .conBox .symbol02,
  #PageIndex #Main #ContBox03 .conBox .answer{
    margin-top:20px;
    float:left;
  }
  
  #PageIndex #Main #ContBox03 .conBox .symbol01{
    width:236px;
    background:url(../img/contents/top/ContBox03_symbol01.png) no-repeat 190px center;
  }
  
  #PageIndex #Main #ContBox03 .conBox .symbol02{
    width:219px;
    background:url(../img/contents/top/ContBox03_symbol02.png) no-repeat 190px center;
  }
  
  #PageIndex #Main #ContBox03 .conBox .answer{
    padding:12px 0 0 16px;
    box-sizing:border-box;
    width:305px;
    height:138px;
  }
  
  #PageIndex #Main #ContBox03 .conBox .subbox_warp .subbox{
    box-sizing:border-box;
    width:175px;
    height:138px;
    background-color:#E6EFFE;
    border-radius:19px;
    -moz-border-radius:19px;
    -webkit-border-radius:19px;
  }
  
  #PageIndex #Main #ContBox03 .conBox .subbox_warp .subbox h4{
    box-sizing:border-box;
    padding-top:54px;
    background:no-repeat center 22px;
    color:#0046b1;
    font-size:20px;
    font-weight:bold;
  }
  
  #PageIndex #Main #ContBox03 .conBox .subbox_warp .subbox h4.title01{
    background-image:url(../img/contents/top/ContBox03_icon03.png);
  }
  
  #PageIndex #Main #ContBox03 .conBox .subbox_warp .subbox h4.title02{
    background-image:url(../img/contents/top/ContBox03_icon02.png);
  }
  
  #PageIndex #Main #ContBox03 .conBox .subbox_warp .subbox h4.title03{
    background-image:url(../img/contents/top/ContBox03_icon04.png);
  }
  
  #PageIndex #Main #ContBox03 .conBox .subbox_warp .subbox p{
    font-size:12px;
    text-align:center;
  }
  
  #PageIndex #Main #ContBox03 .conBox p.txt,
  #PageIndex #Main #ContBox03 .conBox p.price{
    font-weight:bold;
  }
  
  #PageIndex #Main #ContBox03 .conBox p.txt{
    color:#fff;
    font-size:26px;
    line-height:1.2;
  }
  
  #PageIndex #Main #ContBox03 .conBox p.price{
    margin-top:-10px;
    color:#fffc00;
    font-size:44px;
    line-height:1;
  }
  
  #PageIndex #Main #ContBox03 .conBox p.price .font04{
    font-size:56px;
  }
  
  #PageIndex #Main #ContBox03 .conBox .btn01 p,
  #PageIndex #Main #ContBox03 .conBox .btn02 p{
    box-sizing:border-box;
    margin-top:30px;
    text-align:center;
    background:no-repeat center center;
    width:491px;
    height:57px;
    font-size:16px;
    background-color:#022358;
    border-radius:53px;
    -moz-border-radius:53px;
    -webkit-border-radius:53px;
    border:2px solid #627FA9;
    float:left;
  }
  
  #PageIndex #Main #ContBox03 .conBox .btn01 p{
    margin-right:18px;
  }
  
  #PageIndex #Main #ContBox03 .conBox .btn01 p{
    background-image:url(../img/contents/top/ContBox03_icon05.png);
  }
  
  #PageIndex #Main #ContBox03 .conBox .btn02 p{
    background-image:url(../img/contents/top/ContBox03_icon06.png);
  }
  
  #PageIndex #Main #ContBox03 .conBox .btn01 a,
  #PageIndex #Main #ContBox03 .conBox .btn02 a{
    box-sizing:border-box;
    padding-left:34px;
    padding-top:14px;
    display:block;
    width:100%;
    height:100%;
    background:url(../img/contents/top/ContBox03_icon07.png) no-repeat right center;
    color:#fff;
    text-decoration:none;
  }
  
  #PageIndex #Main #ContBox03 .conBox .btn01:hover,
  #PageIndex #Main #ContBox03 .conBox .btn02:hover{
      opacity:0.7;
  }
  
  
  
  /*　#ContBox04
  -------------------------------------*/
  #PageIndex #Main #ContBox04{
    padding-top:80px;
  }
  
  #PageIndex #Main #ContBox04 p.title{
    margin:0 auto;
    width:384px;
    height:44px;
    background:url(../img/contents/top/ContBox04_ribbon.png) no-repeat center top;
    text-indent:-9999px;
  }
  
  #PageIndex #Main #ContBox04 h3{
    margin:11px 0 54px;
    font-size:40px;
    font-weight:bold;
    line-height:1;
  }
  
  #PageIndex #Main #ContBox04 .subbox{
    margin-bottom:20px;
    width:482px;
    height:205px;
    border-radius:8px;
    -moz-border-radius:8px;
    -webkit-border-radius:8px;
    float:left;
  }
  
  #PageIndex #Main #ContBox04 .subbox:first-of-type{
    margin-right:14px;
  }
  
  #PageIndex #Main #ContBox04 .subbox:first-of-type{
    border:5px solid #11B3C0;
  }
  
  #PageIndex #Main #ContBox04 .subbox:nth-of-type(2){
    border:5px solid #BCA734;
  }
  
  #PageIndex #Main #ContBox04 .subbox .title{
    margin-bottom:15px;
    padding:5px 0 17px;
    background-color:#11B3C0;
    color:#fff;
  }
  
  #PageIndex #Main #ContBox04 .subbox .title p{
    text-align:center;
    font-size:18px;
  }
  
  #PageIndex #Main #ContBox04 .subbox .title h4{
    font-size:26px;
  }
  
  #PageIndex #Main #ContBox04 .subbox .title h4 .font03{
    font-size:40px;
  }
  
  #PageIndex #Main #ContBox04 .subbox .title h4 .tax{
    font-size:14px;
  }
  
  #PageIndex #Main #ContBox04 .subbox .sp{
    display:none;
  }
  
  #PageIndex #Main #ContBox04 .subbox ul{
    width:220px;
    float:left;
  }
  
  #PageIndex #Main #ContBox04 .subbox ul:first-of-type{
    margin-left:25px;
  }
  
  #PageIndex #Main #ContBox04 .subbox ul li{
    margin-bottom:6px;
    font-size:16px;
    text-align:left;
    font-weight:bold;
  }
  
  #PageIndex #Main #ContBox04 .subbox ul li:before{
     top: -3px;
  }
  
  #PageIndex #Main #ContBox04 .maintenance{
    box-sizing:border-box;
    padding:15px 0px 0px 30px;
    height:198px;
    background-color:#EEF5FF;
    border:1px solid #DDE7F3;
  }
  
  #PageIndex #Main #ContBox04 .maintenance h3{
    padding-left:30px;
    background:url(../img/contents/top/ContBox04_icon01.png) no-repeat center top;
    font-size:20px;
    color:#0046b1;
    line-height:1;
  }
  
  #PageIndex #Main #ContBox04 .maintenance ul:after{
    content: "";  /* 新しい要素を作る */
    display: block;  /* ブロックレベル要素に */
    clear: both;
  }
  
  
  #PageIndex #Main #ContBox04 .maintenance ul{
    margin-top:-35px;
  }
  
  #PageIndex #Main #ContBox04 .maintenance ul li{
    float:left;
    text-align:left;
    font-size:14px;
    line-height:1.75;
  }
  
  #PageIndex #Main #ContBox04 .maintenance ul li.menu01{
    width:210px;
  }
  
  #PageIndex #Main #ContBox04 .maintenance ul li.menu02{
    width:294px;
  }
  
  #PageIndex #Main #ContBox04 .maintenance ul li.menu03{
    width:223px;
  }
  
  #PageIndex #Main #ContBox04 .maintenance ul li.menu04{
    width:240px;
  }
  
  #PageIndex #Main #ContBox04 .info_warp:after{
    content: "";  /* 新しい要素を作る */
    display: block;  /* ブロックレベル要素に */
    clear: both;
  }
  
  #PageIndex #Main #ContBox04 .info_warp{
    margin-top:20px;
  }
  
  #PageIndex #Main #ContBox04 .info{
    box-sizing:border-box;
    width:370px;
    height:149px;
    background:url(../img/contents/top/ContBox04_img01.png) no-repeat left 15px;
    display:table;
    float:left;
  }
  
  #PageIndex #Main #ContBox04 .info p{
    font-size:14px;
  }
  
  #PageIndex #Main #ContBox04 .info_warp img{
    float:right;
  }
}


@media screen and (max-width: 767px) {
  /*　#Main_img
  -------------------------------------*/
  #PageIndex #Main #Main_img{
    box-sizing:border-box;
    padding-top:17px;
    background:#FFF9BB url(../img/contents/top/sp_main_bk.png) no-repeat center top;
    background-size:568px auto;
    height:401px;
  }
  
  #PageIndex #Main #Main_img .main_box{
    text-indent:-9999px;
  }
  
  #PageIndex #Main #Main_img .main_box .catch01{
    margin-bottom: 1px;
    background:url(../img/contents/top/sp_main_txt01_02.png) no-repeat center top;
    background-size:291px auto;
    height:184px;
  }
  
  #PageIndex #Main #Main_img .main_box .catch02{
    margin-top: -2px;
    background:url(../img/contents/top/sp_main_txt02.png) no-repeat center top;
    background-size:290px auto;
    height:86px;
  }
  
  
  /*　#ConMain02
  -------------------------------------*/
  #PageIndex #Main #ConMain02{
    display:none;
  }
  
  /*　#moreBtn
  -------------------------------------*/
  #PageIndex #Main .moreBox .moreBtn {
    margin: 15px 0;
  }

  
  /*　#ContBox01
  -------------------------------------*/
  #PageIndex #Main #ContBox01{
    box-sizing:border-box;
    padding-top:10px;
    height:115px;
    background:#CA2432 url(../img/contents/top/sp_ContBox01_bk.png) no-repeat center bottom;
    background-size:330px auto;
    color:#fff;
  }
  
  #PageIndex #Main #ContBox01 p{
    font-size:14px;
    text-align:center;
    line-height:1.1;
  }
  
  #PageIndex #Main #ContBox01 h2{
    margin-top:-13px;
    font-size:24px;
    text-align:center;
    line-height:1;
    letter-spacing:0.1em;
    font-weight:bold;
  }
  
  #PageIndex #Main #ContBox01 h2 span.font04{
    font-size:40px;
  }
  
  /*　#ContBox02
  -------------------------------------*/
  #PageIndex #Main #ContBox02{
    padding:23px 0;
    background-color:#FEFFF1;
  }
  
  #PageIndex #Main #ContBox02 h3{
    margin:0 auto 15px;
    width:300px;
    color:#755a30;
    font-size:24px;
    letter-spacing:-0.03em;
    background:url(../img/contents/top/sp_ContBox02_h3_bk.png) no-repeat -8px top;
    background-size:330px auto;
  }
  
  #PageIndex #Main #ContBox02 p.message{
    margin-bottom:15px;
    font-size:17px;
    line-height:1.2;
  }
  
  #PageIndex #Main #ContBox02 p.message:last-of-type{
    padding-bottom:55px;
    background-size:78px auto;
  }
  
  
  #PageIndex #Main #ContBox02 .plan{
    margin-top:-40px;
    padding:15px;
    background:url(../img/contents/top/ContBox02_img02.png) no-repeat right top;
    background-size:74px auto;
    background-color:#fff;
    text-align:left;
    /* box-shadow */
    box-shadow:6px 5px 3px -3px #EFEFE3;
    -moz-box-shadow:6px 5px 3px -3px #EFEFE3;
    -webkit-box-shadow:6px 5px 3px -3px #EFEFE3;
  }
  
  #PageIndex #Main #ContBox02 .plan .img{
    margin:0 auto 6px;
    position:relative;
    width: 203px;
  }
  
  #PageIndex #Main #ContBox02 .plan .img img{
    padding-top: 25px;  
  }
  
  #PageIndex #Main #ContBox02 .plan .img .point{
    top: 6px;
    left: -10px;
    padding:14px 0 0 1px;
    width:47px;
    height:47px;
    background-size:47px auto;
    font-size:12px;
  }
  
  #PageIndex #Main #ContBox02 .plan h4{
    font-size:19px;
  }
  
  #PageIndex #Main #ContBox02 .plan h4 span.font04{
    font-size:29px;
    color:#DA2534;
  }
  
  #PageIndex #Main #ContBox02 .plan p.txt{
    font-size:17px;
  }
  
  /*　#ContBox03
  -------------------------------------*/
  #PageIndex #Main #ContBox03{
    border-top:3px solid #D0D9E8;
  }
  
  #PageIndex #Main #ContBox03 .title{
    padding:30px 0 13px;
    background:#fff url(../img/contents/top/ContBox03_icon01.png) no-repeat center 10px;
    background-size:30px auto;
    line-height:1;
    position: relative;
  }
  
  #PageIndex #Main #ContBox03 .title h3{
    font-size:24px;
    font-weight:bold;
    line-height:0.9;
  }
  
  #PageIndex #Main #ContBox03 .title h3 .font04{
    font-size:40px;
  }
  
  
  #PageIndex #Main #ContBox03 .title h3 .font04:first-of-type{
    position:relative;
    top:2px;
  }
  
  #PageIndex #Main #ContBox03 .conBox .subbox_warp:after{
    content: "";  /* 新しい要素を作る */
    display: block;  /* ブロックレベル要素に */
    clear: both;
  }
  
  #PageIndex #Main #ContBox03 .conBox .symbol01{
    margin-bottom:8px;
    padding-bottom:14px;
    background:url(../img/contents/top/ContBox03_symbol01.png) no-repeat center bottom;
    background-size:22px auto;
  }
  
  #PageIndex #Main #ContBox03 .conBox .symbol02{
    margin-bottom:8px;
    padding-bottom:14px;
    background:url(../img/contents/top/sp_ContBox03_symbol02.png) no-repeat center bottom;
    background-size:12px auto;
  }
  
  #PageIndex #Main #ContBox03 .conBox .subbox_warp{
    margin-bottom:15px;
    background-color:#E6EFFE;
    border-radius:13px;
    -moz-border-radius:13px;
    -webkit-border-radius:13px;
  }
  
  #PageIndex #Main #ContBox03 .conBox .subbox_warp .subbox{
    padding:20px 0 0 0;
    margin:0 auto;
    height:69px;
    width:290px;
  }
  
  #PageIndex #Main #ContBox03 .conBox .subbox_warp .subbox h4{
    float:left;
    width:145px;
    background:no-repeat center 3px;
    color:#0046b1;
    font-size:17px;
    font-weight:bold;
  }
  
  #PageIndex #Main #ContBox03 .conBox .subbox_warp .subbox h4.title01{
    padding-top:24px;
    background-image:url(../img/contents/top/sp_ContBox03_icon03.png) ;
    background-size:47px auto;
  }
  
  #PageIndex #Main #ContBox03 .conBox .subbox_warp .subbox h4.title02{
    padding-top:25px;
    background-image:url(../img/contents/top/sp_ContBox03_icon02.png) ;
    background-size:26px auto;
  }
  
  #PageIndex #Main #ContBox03 .conBox .subbox_warp .subbox h4.title03{
    padding-top:28px;
    background-image:url(../img/contents/top/sp_ContBox03_icon04.png) ;
    background-size:38px auto;
  }
  
  
  #PageIndex #Main #ContBox03 .conBox .subbox_warp .subbox p{
    box-sizing:border-box;
    padding:5px 15px 0 0;
    float:right;
    width:133px;
    letter-spacing:-0.05em;
    font-size:14px;
  }
  
  #PageIndex #Main #ContBox03 .conBox p.txt,
  #PageIndex #Main #ContBox03 .conBox p.price{
    text-align:center;
    font-weight:bold;
    line-height:1;
  }
  
  #PageIndex #Main #ContBox03 .conBox p.txt{
    color:#fff;
    font-size:16px;
  }
  
  #PageIndex #Main #ContBox03 .conBox p.price{
    margin-bottom:20px;
    color:#fffc00;
    font-size:28px;
  }
  
  #PageIndex #Main #ContBox03 .conBox p.price .font04{
    font-size:34px;
  }
  
  #PageIndex #Main #ContBox03 .conBox .btn01{
    text-align:center;
  }
  
  #PageIndex #Main #ContBox03 .conBox .btn01 p,
  #PageIndex #Main #ContBox03 .conBox .btn02 p{
    box-sizing:border-box;
    text-align:center;
    background:no-repeat center center;
    height:56px;
    background-color:#022358;
    border-radius:53px;
    -moz-border-radius:53px;
    -webkit-border-radius:53px;
    border:2px solid #627FA9;
  }
  
  #PageIndex #Main #ContBox03 .conBox .btn01 p{
    background-image:url(../img/contents/top/sp_ContBox03_icon05.png);
    background-size:155px auto;
  }
  
  #PageIndex #Main #ContBox03 .conBox .btn02 p{
    background-image:url(../img/contents/top/sp_ContBox03_icon06.png);
    background-size:123px auto;
  }
  
  #PageIndex #Main #ContBox03 .conBox .btn01 a,
  #PageIndex #Main #ContBox03 .conBox .btn02 a{
    box-sizing:border-box;
    padding-left:34px;
    padding-top:15px;
    display:block;
    width:100%;
    height:100%;
    background:url(../img/contents/top/sp_ContBox03_icon07.png) no-repeat right center;
    background-size:31px auto;
    color:#fff;
    text-decoration:none;
  }
  
  
  /*　#ContBox04
  -------------------------------------*/
  #PageIndex #Main #ContBox04{
    padding-top:35px;
  }
  
  #PageIndex #Main #ContBox04 p.title{
    margin:0 auto 5px;
    width:290px;
    height:34px;
    background:url(../img/contents/top/sp_ContBox04_ribbon.png) no-repeat left top;
    background-size:290px auto;
    text-indent:-9999px;
  }
  
  #PageIndex #Main #ContBox04 h3{
    margin-bottom:20px;
    font-size:24px;
    font-weight:bold;
    line-height:1;
  }
  
  #PageIndex #Main #ContBox04 .subbox{
    margin-bottom:11px;
    /* border-radius */
    border-radius:6px;
    -moz-border-radius:6px;
    -webkit-border-radius:6px;
  }
  
  #PageIndex #Main #ContBox04 .subbox:first-of-type{
    border:4px solid #11B3C0;
  }
  
  #PageIndex #Main #ContBox04 .subbox:nth-of-type(2){
    border:4px solid #BCA734;
  }
  
  #PageIndex #Main #ContBox04 .subbox .title{
    padding:10px 0;
    background-color:#11B3C0;
    color:#fff;
  }
  
  #PageIndex #Main #ContBox04 .subbox .title p{
    text-align:center;
    font-size:13px;
  }
  
  #PageIndex #Main #ContBox04 .subbox .title h4{
    font-size:20px;
  }
  
  #PageIndex #Main #ContBox04 .subbox .title h4 .font03{
    font-size:34px;
  }
  
  #PageIndex #Main #ContBox04 .subbox .title h4 .tax{
    font-size:10px;
  }
  
  #PageIndex #Main #ContBox04 .subbox .pc{
    display:none;
  }
  
  #PageIndex #Main #ContBox04 .subbox ul{
    padding:15px 0 15px 30px;
  }
  
  #PageIndex #Main #ContBox04 .subbox ul.first{
    padding:15px 0 0 30px;
  }
  
  #PageIndex #Main #ContBox04 .subbox ul.second{
    padding:0 0 15px 30px;
  }
  
  
  #PageIndex #Main #ContBox04 .subbox ul li{
    text-align:left;
    font-weight:bold;
    line-height:2;
  }
  
  #PageIndex #Main #ContBox04 .subbox ul li:before{
     top: -5px;
  }
  
  #PageIndex #Main #ContBox04 .maintenance{
    padding:10px 10px 20px 10px;
    background-color:#EEF5FF;
    border:1px solid #DDE7F3;
  }
  
  #PageIndex #Main #ContBox04 .maintenance h3{
    padding-top:20px;
    background:url(../img/contents/top/sp_ContBox04_icon01.png) no-repeat center top;
    font-size:20px;
    color:#0046b1;
    line-height:1.2;
  }
  
  #PageIndex #Main #ContBox04 .maintenance ul{
    margin-top:-10px;
  }
  
  
  #PageIndex #Main #ContBox04 .maintenance ul li{
    text-align:left;
    font-size:15px;
    line-height:1.6;
  }
  
  #PageIndex #Main #ContBox04 .info_warp{
    margin-top:10px;
  }
  
  #PageIndex #Main #ContBox04 .info{
    margin-bottom:10px;
    box-sizing:border-box;
    height:119px;
    background:url(../img/contents/top/ContBox04_img01.png) no-repeat left top;
    display:table;
  }
  
  #PageIndex #Main #ContBox04 .info p{
    font-size:15px;
  }

  #PageIndex {
    #Main {
      #ContBox05 {
        margin-top: 40px;
        padding: 39px 0 0;
        .title {
          font-size: 24px;
          margin-bottom: 23px;
        }
        .blogButton {
          margin: 25px auto 0;
        }
      }
    }
  }
}

/*####################　PageCar　####################*/
/*　#MainTitleWarp
-------------------------------------*/
#PageCar #MainTitleWarp h2{
  background-image:url(../img/contents/car/main_bk.png);
}

  /*　共通
-------------------------------------*/
/* .title */
#PageCar #Main .title{
  box-sizing:border-box;
  color:#fff;
}

#PageCar #Main .title h3 span{
  color:#FFFC01;
}

/* h4 */
#PageCar #Main h4{
  font-weight:bold;
  text-align:center;
}

/* .cost */
#PageCar #Main p.cost{
  margin-bottom:33px;
  background-color:#EEF5FF;
  border:1px solid #DDE7F3;
  color:#0046b1;
  text-align:center;
  border-radius:6px;
  -moz-border-radius:6px;
  -webkit-border-radius:6px;
}



/*　#ContBox01
-------------------------------------*/
#PageCar #Main #ContBox01 ul li{
  box-sizing:border-box;
  margin-bottom:10px;
}
#PageCar #Main #ContBox01 ul li a{
  box-sizing:border-box;
  display:block;
  border-radius:53px;
  -moz-border-radius:53px;
  -webkit-border-radius:53px;
  border:2px solid #D9D9D9;
  width:100%;
  height:100%;
  background:url(../img/contents/car/icon_link.png) no-repeat right -3px center;
  background-color:#fff;
  text-align:center;
  letter-spacing:0.05em;
  text-decoration:none;
  font-weight:bold;
  font-size:16px;
  height:56px;
}
#PageCar #Main #ContBox01 ul li a span{
  color: #da2634;
}

#PageCar #Main #ContBox01 ul li a span.font03{
  position:relative;
  top:2px;
  font-size:26px;
}


/* .car_box */
#PageCar #Main .car_box figure::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: center center;
}
#PageCar #Main #ContBox02 .car_box figure::after{ background-image: url(../img/contents/car_202107/price_8000.png);}
#PageCar #Main #ContBox13 .car_box figure::after{ background-image: url(../img/contents/car_202107/price_9000.png);}
#PageCar #Main #ContBox03 .car_box figure::after{ background-image: url(../img/contents/car_202107/price_10000.png);}
#PageCar #Main #ContBox07 .car_box figure::after{ background-image: url(../img/contents/car_202107/price_11000.png);}
#PageCar #Main #ContBox08 .car_box figure::after{ background-image: url(../img/contents/car_202107/price_12000.png);}
#PageCar #Main #ContBox04 .car_box figure::after{ background-image: url(../img/contents/car_202107/price_13000.png);}
#PageCar #Main #ContBox06 .car_box figure::after{ background-image: url(../img/contents/car_202107/price_14000.png);}
#PageCar #Main #ContBox10 .car_box figure::after{ background-image: url(../img/contents/car_202107/price_15000.png);}
#PageCar #Main #ContBox11 .car_box figure::after{ background-image: url(../img/contents/car_202107/price_17000.png);}
#PageCar #Main #ContBox12 .car_box figure::after{ background-image: url(../img/contents/car_202107/price_18000.png);}

#PageCar #Main .car_box table tr{
  border-bottom:1px solid #E7E7E7;
}

#PageCar #Main .car_box table th{
  color:#888;
}

#PageCar #Main .car_box table td.name{
    font-weight:bold;
    padding: 9px 0;
    vertical-align: middle;
}
#PageCar #Main .car_box table .fuel td::before {
  content: "";
  width: 42px;
  height: 11px;
  display: block;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../img/contents/car/fuel_symbol_02.png);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
#PageCar #Main .car_box.jc08 table .fuel td::before {
  width: 41px;
  background-image: url(../img/contents/car/fuel_symbol.png);
}
#PageCar #Main .about ul::after{
  content: "";
  display: block;
  clear: both;
}
#PageCar #Main .about ul .circle {
  margin-top: 4px;
  margin-right: 8px;
  margin-bottom: 4px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  float: left;
}
#PageCar #Main .about ul .circle:last-of-type {
  margin-right: 0;
}
/*車の色*/
/*6千円プラン*/
#PageCar #Main #ContBox05 .car01 .color01{background-color: #fff;border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox05 .car01 .color02{background-color: #afb7bc;}

#PageCar #Main #ContBox05 .car02 .color01{background-color: #dc2e12;}
#PageCar #Main #ContBox05 .car02 .color02{background-color: #64aeb0;}
#PageCar #Main #ContBox05 .car02 .color03{background-color: #6b5c3a;}
#PageCar #Main #ContBox05 .car02 .color04{background-color: #e0c8c0;}
#PageCar #Main #ContBox05 .car02 .color05{background-color: #9eb3c5;}
#PageCar #Main #ContBox05 .car02 .color06{background-color: #000;}
#PageCar #Main #ContBox05 .car02 .color07{background-color: #fff;border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox05 .car02 .color08{background-color: #bfc2c4;}

/*8千円プラン*/
#PageCar #Main #ContBox02 .car01 .color01{background-color: #bed8e3;}
/*#PageCar #Main #ContBox02 .car01 .color02{background-color: #f5e911;}*/
/*#PageCar #Main #ContBox02 .car01 .color03{background-color: #0094d3;}*/
/*#PageCar #Main #ContBox02 .car01 .color04{background-color: #d02127;}*/
/*#PageCar #Main #ContBox02 .car01 .color05{background-color: #662631;}*/
/*#PageCar #Main #ContBox02 .car01 .color06{background-color: #000;}*/
/*#PageCar #Main #ContBox02 .car01 .color07{background-color: #fefeff;border: 1px solid #999;box-sizing: border-box;}*/
#PageCar #Main #ContBox02 .car01 .color08{background-color: #e3e7ec;}
#PageCar #Main #ContBox02 .car01 .color09{background-color: #fff;border: 1px solid #999;box-sizing: border-box;}

#PageCar #Main #ContBox02 .car02 .color01{background-color: #c5c3c3;}
#PageCar #Main #ContBox02 .car02 .color02{background-color: #fff;border: 1px solid #999;box-sizing: border-box;}

/*9千円プラン*/
#PageCar #Main #ContBox13 .car01 .color01{background-color: #7eb5c4;}
#PageCar #Main #ContBox13 .car01 .color02{background-color: #93a0a9;}
#PageCar #Main #ContBox13 .car01 .color03{background-color: #fff;border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox13 .car02 .color01{background-color: #d4dae0;}
#PageCar #Main #ContBox13 .car02 .color02{background-color: #fff;border: 1px solid #999;box-sizing: border-box;}

/*1万円プラン*/
#PageCar #Main #ContBox03 .car01 .color01{background-color: #f9dd7f;}
#PageCar #Main #ContBox03 .car01 .color02{background-color: #c8b588;}
#PageCar #Main #ContBox03 .car01 .color03{background-color: #c41029;}
#PageCar #Main #ContBox03 .car01 .color04{background-color: #038cd6;}
#PageCar #Main #ContBox03 .car01 .color05{background-color: #fcfcfc;border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox03 .car01 .color06{background-color: #bec2c2;}
#PageCar #Main #ContBox03 .car01 .color07{background-color: #b3d7e5;}
#PageCar #Main #ContBox03 .car01 .color08{background-color: #5d4949;}
#PageCar #Main #ContBox03 .car01 .color09{background-color: #0a1925;}
#PageCar #Main #ContBox03 .car01 .color10{background-color: #e6e6e0;}

#PageCar #Main #ContBox03 .car02 .color01{background-color: #eeedf2;}
#PageCar #Main #ContBox03 .car02 .color02{background-color: #fff;border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox03 .car02 .color03{background-color: #d12627;}
#PageCar #Main #ContBox03 .car02 .color04{background-color: #7dcee0;}
#PageCar #Main #ContBox03 .car02 .color05{background-color: #cccccc;}
#PageCar #Main #ContBox03 .car02 .color06{background-color: #722c0d;}
#PageCar #Main #ContBox03 .car02 .color07{background-color: #12718c;}
#PageCar #Main #ContBox03 .car02 .color08{background-color: #f1ccdd;}
#PageCar #Main #ContBox03 .car02 .color09{background-color: #0b8f69;}
#PageCar #Main #ContBox03 .car02 .color10{background-color: #000000;}

#PageCar #Main #ContBox03 .car03 .color01{background-color: #cde8d8;}
#PageCar #Main #ContBox03 .car03 .color02{background-color: #eae285;}
#PageCar #Main #ContBox03 .car03 .color03{background-color: #e8c3c6;}
#PageCar #Main #ContBox03 .car03 .color04{background-color: #fefefe;border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox03 .car03 .color05{background-color: #fff;border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox03 .car03 .color06{background-color: #231815;}
#PageCar #Main #ContBox03 .car03 .color07{background-color: #d4d4d4;}
#PageCar #Main #ContBox03 .car03 .color08{background-color: #16637e;}
#PageCar #Main #ContBox03 .car03 .color09{background-color: #ac2238;}

#PageCar #Main #ContBox03 .car04 .color01{background-color: #bdd5c0;}
#PageCar #Main #ContBox03 .car04 .color02{background-color: #84b2be;}
#PageCar #Main #ContBox03 .car04 .color03{background-color:#fff;border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox03 .car04 .color04{background-color: #000;}
#PageCar #Main #ContBox03 .car04 .color05{background-color: #6a2a37;}
#PageCar #Main #ContBox03 .car04 .color06{background-color: #c5b792;}
#PageCar #Main #ContBox03 .car04 .color07{background-color: #b02536;}
#PageCar #Main #ContBox03 .car04 .color08{background-color: #e3b4ab;}
#PageCar #Main #ContBox03 .car04 .color09{background-color: #909296;}

/*1.1万円プラン*/
#PageCar #Main #ContBox07 .car01 .color01{background-color: #d6efee;}
#PageCar #Main #ContBox07 .car01 .color02{background-color: #e3c2b6;}
#PageCar #Main #ContBox07 .car01 .color03{background-color: #aba499;}
#PageCar #Main #ContBox07 .car01 .color04{background-color: #efe4ba;}
#PageCar #Main #ContBox07 .car01 .color05{background-color: #595452;}
#PageCar #Main #ContBox07 .car01 .color06{background-color: #2d446c;}
#PageCar #Main #ContBox07 .car01 .color07{background-color: #e6e6e0;}

#PageCar #Main #ContBox07 .car02 .color01{background-color: #a9b2a3;}
#PageCar #Main #ContBox07 .car02 .color02{background-color: #7da8ad;}
#PageCar #Main #ContBox07 .car02 .color03{background-color: #c75d7e;}
#PageCar #Main #ContBox07 .car02 .color04{background-color: #f0f2f2;}
#PageCar #Main #ContBox07 .car02 .color05{background-color: #000007;}
#PageCar #Main #ContBox07 .car02 .color06{background-color: #f1e4af;}
#PageCar #Main #ContBox07 .car02 .color07{background-color: #c5ced7;}
#PageCar #Main #ContBox07 .car02 .color08{background-color: #512c20;}
#PageCar #Main #ContBox07 .car02 .color09{background-color: #0070a9;}

/*1.2万円プラン*/
#PageCar #Main #ContBox08 .car01 .color01{background-color: #dae6ec;}
#PageCar #Main #ContBox08 .car01 .color02{background-color: #f8f8f8;border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox08 .car01 .color03{background-color: #dcdcdc;}
#PageCar #Main #ContBox08 .car01 .color04{background-color: #231816;}
#PageCar #Main #ContBox08 .car01 .color05{background-color: #D9B5B3;}
#PageCar #Main #ContBox08 .car01 .color06{background-color: #431A15;}
#PageCar #Main #ContBox08 .car01 .color07{background-color: #003f34;}

#PageCar #Main #ContBox08 .car02 .color01{background-color: #8ea8af;}
#PageCar #Main #ContBox08 .car02 .color02{background-color: #819069;}
#PageCar #Main #ContBox08 .car02 .color03{background-color: #cbb891;}
#PageCar #Main #ContBox08 .car02 .color04{background-color: #fff;;border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox08 .car02 .color05{background-color: #ccc;}

#PageCar #Main #ContBox08 .car04 .color01{background-color: #fff;border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox08 .car04 .color02{background-color: #000;}
#PageCar #Main #ContBox08 .car04 .color03{background-color: #b02737;}
#PageCar #Main #ContBox08 .car04 .color04{background-color: #6b2c38;}
#PageCar #Main #ContBox08 .car04 .color05{background-color: #ecc8c5;}
#PageCar #Main #ContBox08 .car04 .color06{background-color: #00593d;}
#PageCar #Main #ContBox08 .car04 .color07{background: linear-gradient( 0deg, #fff 50%, #87053c 50% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox08 .car04 .color08{background: linear-gradient( 0deg, #000 50%, #fff 50% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox08 .car04 .color09{background: linear-gradient( 0deg, #000 50%, #87053a 50% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox08 .car04 .color10{background: linear-gradient( 0deg, #b02737 50%, #fff 50% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox08 .car04 .color11{background: linear-gradient( 0deg, #6b2c38 50%, #fff 50% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox08 .car04 .color12{background: linear-gradient( 0deg, #ecc8c5 50%, #fff 50% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox08 .car04 .color13{background: linear-gradient( 0deg, #00593d 50%, #fff 50% );border: 1px solid #999;box-sizing: border-box;}

#PageCar #Main #ContBox08 .car05 .color01{background: linear-gradient( 0deg, #eca395 66%, #dadada 66% );box-sizing: border-box;}
#PageCar #Main #ContBox08 .car05 .color02{background: linear-gradient( 0deg, #424c61 66%, #595757 66% );box-sizing: border-box;}
#PageCar #Main #ContBox08 .car05 .color03{background: linear-gradient( 0deg, #a72126 66%, #595757 66% );box-sizing: border-box;}
#PageCar #Main #ContBox08 .car05 .color04{background: linear-gradient( 0deg, #d4698e 66%, #fff 66% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox08 .car05 .color05{background: linear-gradient( 0deg, #f3c517 66%, #fff 66% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox08 .car05 .color06{background: linear-gradient( 0deg, #187fc4 66%, #fff 66% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox08 .car05 .color07{background-color: #f0e5ca;}
#PageCar #Main #ContBox08 .car05 .color08{background-color: #5b99a6;}
#PageCar #Main #ContBox08 .car05 .color09{background-color: #000007;}
#PageCar #Main #ContBox08 .car05 .color10{background-color: #ecebe6;}
#PageCar #Main #ContBox08 .car05 .color11{background-color: #515f46;}

/*1.3万円プラン*/
#PageCar #Main #ContBox04 .car01 .color01{background-color: #cee8d8;}
#PageCar #Main #ContBox04 .car01 .color02{background-color: #eae285;}
#PageCar #Main #ContBox04 .car01 .color03{background-color: #e8c3c6;}
#PageCar #Main #ContBox04 .car01 .color04{background-color: #fefefe;border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox04 .car01 .color05{background-color: #fff;;border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox04 .car01 .color06{background-color: #231814;}
#PageCar #Main #ContBox04 .car01 .color07{background-color: #d4d4d4;}
#PageCar #Main #ContBox04 .car01 .color08{background-color: #16637e;}
#PageCar #Main #ContBox04 .car01 .color09{background-color: #ac2238;}
#PageCar #Main #ContBox04 .car01 .color10{background: linear-gradient( 0deg, #fff 50%, #cee8d8 50% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox04 .car01 .color11{background: linear-gradient( 0deg, #e8c3c6 50%, #fff 50% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox04 .car01 .color12{background: linear-gradient( 0deg, #16637e 50%, #fff 50% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox04 .car01 .color13{background: linear-gradient( 0deg, #ac2238 50%, #fff 50% );border: 1px solid #999;box-sizing: border-box;}

#PageCar #Main #ContBox04 .car02 .color01{background-color: #a9b2a3;}
#PageCar #Main #ContBox04 .car02 .color02{background-color: #7da8ad;}
#PageCar #Main #ContBox04 .car02 .color03{background-color: #c75d7e;}
#PageCar #Main #ContBox04 .car02 .color04{background-color: #f0f2f2;}
#PageCar #Main #ContBox04 .car02 .color05{background-color: #000007;}
#PageCar #Main #ContBox04 .car02 .color06{background-color: #f1e4af;}
#PageCar #Main #ContBox04 .car02 .color07{background-color: #c5ced7;}
#PageCar #Main #ContBox04 .car02 .color08{background-color: #512c20;}
#PageCar #Main #ContBox04 .car02 .color09{background-color: #0070a9;}
#PageCar #Main #ContBox04 .car02 .color10{background: linear-gradient( 0deg, #536548 66%, #fff 66% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox04 .car02 .color11{background: linear-gradient( 0deg, #7da8ad 66%, #fff 66% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox04 .car02 .color12{background: linear-gradient( 0deg, #c75d7e 66%, #fff 66% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox04 .car02 .color13{background: linear-gradient( 0deg, #f1e4af 66%, #fff 66% );border: 1px solid #999;box-sizing: border-box;}

#PageCar #Main #ContBox04 .car03 .color01{background-color: #dae6ec;}
#PageCar #Main #ContBox04 .car03 .color02{background-color: #f8f8f8;border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox04 .car03 .color03{background-color: #dcdcdc;}
#PageCar #Main #ContBox04 .car03 .color04{background-color: #231816;}
#PageCar #Main #ContBox04 .car03 .color05{background-color: #D9B5B3;}
#PageCar #Main #ContBox04 .car03 .color06{background-color: #431A15;}
#PageCar #Main #ContBox04 .car03 .color07{background-color: #003f34;}

#PageCar #Main #ContBox04 .car04 .color01{background-color: #60c1bd;}
#PageCar #Main #ContBox04 .car04 .color02{background-color: #9f9b9d;}
#PageCar #Main #ContBox04 .car04 .color03{background-color: #cebfa7;}
#PageCar #Main #ContBox04 .car04 .color04{background-color: #cf6b95;}
#PageCar #Main #ContBox04 .car04 .color05{background-color: #fff;border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox04 .car04 .color06{background-color: #c92c27;}
#PageCar #Main #ContBox04 .car04 .color07{background-color: #6a282d;}
#PageCar #Main #ContBox04 .car04 .color08{background-color: #3f3a39;}
#PageCar #Main #ContBox04 .car04 .color09{background: linear-gradient( 0deg, #fff 25%, #60c1bd 60%, #fff 80% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox04 .car04 .color10{background: linear-gradient( 0deg, #fff 25%, #9f9b9d 60%, #fff 80% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox04 .car04 .color11{background: linear-gradient( 0deg, #fff 25%, #cebfa7 60%, #fff 80% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox04 .car04 .color12{background: linear-gradient( 0deg, #fff 25%, #cf6b95 60%, #fff 80% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox04 .car04 .color13{background: linear-gradient( 0deg, #9e9a9c 25%, #c92c27 60%, #9e9a9c 80% );}
#PageCar #Main #ContBox04 .car04 .color14{background: linear-gradient( 0deg, #9e9a9c 25%, #6a282d 60%, #9e9a9c 80% );}
#PageCar #Main #ContBox04 .car04 .color15{background: linear-gradient( 0deg, #9e9a9c 25%, #3f3a39 60%, #9e9a9c 80% );}

#PageCar #Main #ContBox04 .car05 .color01{background-color: #9f8764;}
#PageCar #Main #ContBox04 .car05 .color02{background-color: #b34c33;}
#PageCar #Main #ContBox04 .car05 .color03{background-color: #8ea8af;}
#PageCar #Main #ContBox04 .car05 .color04{background-color: #6d3135;}
#PageCar #Main #ContBox04 .car05 .color05{background-color: #11708e;}
#PageCar #Main #ContBox04 .car05 .color06{background-color: #fff;border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox04 .car05 .color07{background-color: #6c777a;}
#PageCar #Main #ContBox04 .car05 .color08{background-color: #000;}
#PageCar #Main #ContBox04 .car05 .color09{background: linear-gradient( 0deg, #9f8764 50%, #fff 50% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox04 .car05 .color10{background: linear-gradient( 0deg, #b34c33 50%, #fff 50% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox04 .car05 .color11{background: linear-gradient( 0deg, #8ea8af 50%, #fff 50% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox04 .car05 .color12{background: linear-gradient( 0deg, #11708e 50%, #fff 50% );border: 1px solid #999;box-sizing: border-box;}

/*1.4万円プラン*/
#PageCar #Main #ContBox06 .car01 .color01{background-color: #7f7f82;}
#PageCar #Main #ContBox06 .car01 .color02{background-color: #353a2e;}
#PageCar #Main #ContBox06 .car01 .color03{background-color: #c5ced7;}
#PageCar #Main #ContBox06 .car01 .color04{background-color: #f0f2f2;border: 1px solid #999;box-sizing: border-box;}

#PageCar #Main #ContBox06 .car02 .color01{background-color: #a9b2a3;}
#PageCar #Main #ContBox06 .car02 .color02{background-color: #4a3946;}
#PageCar #Main #ContBox06 .car02 .color03{background-color: #000007;}
#PageCar #Main #ContBox06 .car02 .color04{background-color: #f0f2f2;border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox06 .car02 .color05{background-color: #c5ced7;}

#PageCar #Main #ContBox06 .car03 .color01{background-color: #9f8764;}
#PageCar #Main #ContBox06 .car03 .color02{background-color: #b34c33;}
#PageCar #Main #ContBox06 .car03 .color03{background-color: #8ea8af;}
#PageCar #Main #ContBox06 .car03 .color04{background-color: #6d3135;}
#PageCar #Main #ContBox06 .car03 .color05{background-color: #11708e;}
#PageCar #Main #ContBox06 .car03 .color06{background-color: #fff;border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox06 .car03 .color07{background-color: #6c777a;}
#PageCar #Main #ContBox06 .car03 .color08{background-color: #000;}
#PageCar #Main #ContBox06 .car03 .color09{background: linear-gradient( 0deg, #9f8764 50%, #fff 50% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox06 .car03 .color10{background: linear-gradient( 0deg, #b34c33 50%, #fff 50% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox06 .car03 .color11{background: linear-gradient( 0deg, #8ea8af 50%, #fff 50% );border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox06 .car03 .color12{background: linear-gradient( 0deg, #11708e 50%, #fff 50% );border: 1px solid #999;box-sizing: border-box;}

/*1.5万円プラン*/
#PageCar #Main #ContBox10 .car01 .color01{background-color: #c98191;}
#PageCar #Main #ContBox10 .car01 .color02{background-color: #f0f2f2;border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox10 .car01 .color03{background-color: #0070a9;}
#PageCar #Main #ContBox10 .car01 .color04{background-color: #512c20;}
#PageCar #Main #ContBox10 .car01 .color05{background-color: #8c9694;}
#PageCar #Main #ContBox10 .car01 .color06{background-color: #533247;}
#PageCar #Main #ContBox10 .car01 .color07{background-color: #000007;}

#PageCar #Main #ContBox10 .car02 .color01{background-color: #f8f8f8;border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox10 .car02 .color02{background-color: #7d8888;}
#PageCar #Main #ContBox10 .car02 .color03{background-color: #231816;}
#PageCar #Main #ContBox10 .car02 .color04{background-color: #584F40;}
#PageCar #Main #ContBox10 .car02 .color05{background-color: #071f5b;}
#PageCar #Main #ContBox10 .car02 .color06{background-color: #402481;}

#PageCar #Main #ContBox10 .car03 .color01{background-color: #fefeff;border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox10 .car03 .color02{background-color: #161d24;}
#PageCar #Main #ContBox10 .car03 .color03{background-color: #231815;}
#PageCar #Main #ContBox10 .car03 .color04{background-color: #c9ced2;}
#PageCar #Main #ContBox10 .car03 .color05{background-color: #743f34;}
#PageCar #Main #ContBox10 .car03 .color06{background-color: #e9e285;}
#PageCar #Main #ContBox10 .car03 .color07{background-color: #15637d;}
#PageCar #Main #ContBox10 .car03 .color08{background-color: #9c3335;}
#PageCar #Main #ContBox10 .car03 .color01{background: linear-gradient( 0deg, #fefeff 50%, #161d24 50% );}
#PageCar #Main #ContBox10 .car03 .color07{background: linear-gradient( 0deg, #15637d 50%, #161d24 50% );}
#PageCar #Main #ContBox10 .car03 .color08{background: linear-gradient( 0deg, #9c3335 50%, #161d24 50% );}

#PageCar #Main #ContBox10 .car04 .color01{background: linear-gradient( 0deg, #eecf85 66%, #dcdcdc 66% );}
#PageCar #Main #ContBox10 .car04 .color02{background: linear-gradient( 0deg, #347184 66%, #494949 66% );}
#PageCar #Main #ContBox10 .car04 .color03{background: linear-gradient( 0deg, #c2394b 66%, #494949 66% );}
#PageCar #Main #ContBox10 .car04 .color04{background: linear-gradient( 0deg, #749db2 66%, #494949 66% );}
#PageCar #Main #ContBox10 .car04 .color05{background-color: #e6e6e0;}
#PageCar #Main #ContBox10 .car04 .color06{background-color: #0a1925;}
#PageCar #Main #ContBox10 .car04 .color07{background: linear-gradient( 0deg, #385d45 66%, #494949 66% );}
#PageCar #Main #ContBox10 .car04 .color08{background-color: #c8b588;}
#PageCar #Main #ContBox10 .car04 .color09{background-color: #909ba0;}

#PageCar #Main #ContBox10 .car05 .color01{background-color: #a9b2a3;}
#PageCar #Main #ContBox10 .car05 .color02{background-color: #4a3946;}
#PageCar #Main #ContBox10 .car05 .color03{background-color: #000007;}
#PageCar #Main #ContBox10 .car05 .color04{background-color: #f0f2f2;border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox10 .car05 .color05{background-color: #c5ced7;}

#PageCar #Main #ContBox10 .car06 .color01{background-color: #e7eb9a;}
#PageCar #Main #ContBox10 .car06 .color02{background-color: #f1e4af;}
#PageCar #Main #ContBox10 .car06 .color03{background-color: #0070a9;}
#PageCar #Main #ContBox10 .car06 .color04{background-color: #94989a;}
#PageCar #Main #ContBox10 .car06 .color05{background-color: #000007;}
#PageCar #Main #ContBox10 .car06 .color06{background-color: #353a2e;}
#PageCar #Main #ContBox10 .car06 .color07{background-color: #c5ced7;}
#PageCar #Main #ContBox10 .car06 .color08{background-color: #f0f2f2;border: 1px solid #999;box-sizing: border-box;}

/*1.7万円プラン*/
#PageCar #Main #ContBox11 .car01 .color01{background-color: #b38d92;}
#PageCar #Main #ContBox11 .car01 .color02{background-color: #e0522c;}
#PageCar #Main #ContBox11 .car01 .color03{background-color: #0074bd;}
#PageCar #Main #ContBox11 .car01 .color04{background-color: #4ea8be;}
#PageCar #Main #ContBox11 .car01 .color05{background-color: #664343;}
#PageCar #Main #ContBox11 .car01 .color06{background-color: #cbd5db;}
#PageCar #Main #ContBox11 .car01 .color07{background-color: #f0f2f2;}
#PageCar #Main #ContBox11 .car01 .color08{background-color: #1d2425;}

#PageCar #Main #ContBox11 .car02 .color01{background-color: #066998;}
#PageCar #Main #ContBox11 .car02 .color02{background-color: #118ebf;}
#PageCar #Main #ContBox11 .car02 .color03{background-color: #58548c;}
#PageCar #Main #ContBox11 .car02 .color04{background-color: #821c21;}
#PageCar #Main #ContBox11 .car02 .color05{background-color: #c4283f;}
#PageCar #Main #ContBox11 .car02 .color06{background-color: #b7c0c6;}
#PageCar #Main #ContBox11 .car02 .color07{background-color: #ad7559;}
#PageCar #Main #ContBox11 .car02 .color08{background-color: #fffef7;border: 1px solid #999;box-sizing: border-box;}
#PageCar #Main #ContBox11 .car02 .color09{background-color: #0e2023;}

/*1.8万円プラン*/
#PageCar #Main #ContBox12 .car01 .color01{background-color: #b38d92;}
#PageCar #Main #ContBox12 .car01 .color02{background-color: #e0522c;}
#PageCar #Main #ContBox12 .car01 .color03{background-color: #0074bd;}
#PageCar #Main #ContBox12 .car01 .color04{background-color: #4ea8be;}
#PageCar #Main #ContBox12 .car01 .color05{background-color: #664343;}
#PageCar #Main #ContBox12 .car01 .color06{background-color: #cbd5db;}
#PageCar #Main #ContBox12 .car01 .color07{background-color: #f0f2f2;}
#PageCar #Main #ContBox12 .car01 .color08{background-color: #1d2425;}

#PageCar #Main #ContBox12 .car02 .color01{background-color: #b38d92;}
#PageCar #Main #ContBox12 .car02 .color02{background-color: #e0522c;}
#PageCar #Main #ContBox12 .car02 .color03{background-color: #0074bd;}
#PageCar #Main #ContBox12 .car02 .color04{background-color: #4ea8be;}
#PageCar #Main #ContBox12 .car02 .color05{background-color: #664343;}
#PageCar #Main #ContBox12 .car02 .color06{background-color: #cbd5db;}
#PageCar #Main #ContBox12 .car02 .color07{background-color: #f0f2f2;}
#PageCar #Main #ContBox12 .car02 .color08{background-color: #1d2425;}

#PageCar #Main #ContBox12 .car03 .color01{background-color: #e9e9e1;}
#PageCar #Main #ContBox12 .car03 .color02{background-color: #0e2023;}
#PageCar #Main #ContBox12 .car03 .color03{background-color: #b7c0c6;}
#PageCar #Main #ContBox12 .car03 .color04{background-color: #c4283f;}
#PageCar #Main #ContBox12 .car03 .color05{background-color: #a5988c;}
#PageCar #Main #ContBox12 .car03 .color06{background-color: #c6c268;}
#PageCar #Main #ContBox12 .car03 .color07{background-color: #066998;}
#PageCar #Main #ContBox12 .car03 .color08{background-color: #19b5ce;}


#PageCar #Main .about ul .carColorWhite {
  background-color: #fff;
  border: 1px solid #999;
  box-sizing: border-box;
}
#PageCar #Main .about ul .carColorRed {
  background-color: #ca2431;
}
#PageCar #Main .about ul .carColorGray {
  background-color: #afb7bc;
  border: none;
}
#PageCar #Main .about ul .carColor {
  background-color: #000000;
  border: none;
}
@media print, screen and (min-width: 768px) {
  /*　共通
  -------------------------------------*/
  /* .contBox */
  #PageCar #Main .contBox{
    margin-bottom: 81px;
    padding-top: 0;
    margin-top: 0;
  }

  #PageCar #Main .contBox:last-of-type{
    margin: 40px 0 210px;
  }
  /* .title */
  #PageCar #Main .title{
    margin-bottom: 60px;
    padding-top:18px;
    height:120px;
    background:#CA2432 url(../img/contents/car/title_bk.png) no-repeat center bottom;
  }

  #PageCar #Main .title p{
    letter-spacing: 0.6em;
    font-size:16px;
    text-align:center;
  }

  /*#PageCar #Main .contBox .title p.title01{
    letter-spacing:0.73em;
  }

  #PageCar #Main .contBox .title p.title02,
  #PageCar #Main .contBox .title p.title03{
    letter-spacing:0.6em;
  }*/


  #PageCar #Main .title h3{
    margin-top: -15px;
    font-size: 40px;
    text-align: center;
    line-height: 1;
    font-weight: bold;
    letter-spacing: 0.1em;
    position: relative;
  }
  /*#PageCar #Main .title h3.standard::after{
    content: "標準車";
    border: 1px solid #e59298;
    border-radius: 3px;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: normal;
    padding: 7px 9px;
    position: absolute;
    right: 0;
    bottom: 8px;
  }
  #PageCar #Main .title h3.custom::after{
    content: "カスタム";
    border: 1px solid #e59298;
    border-radius: 3px;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: normal;
    padding: 7px 6px;
    position: absolute;
    right: 0;
    bottom: 8px;
  }*/
  #PageCar #Main .title h3 span.font03{
    position: relative;
    font-size: 66px;
    top: 3px;
  }

  #PageCar #Main #ContBox07 .title h3 span.font03,
  #PageCar #Main #ContBox08 .title h3 span.font03,
  #PageCar #Main #ContBox04 .title h3 span.font03,
  #PageCar #Main #ContBox06 .title h3 span.font03,
  #PageCar #Main #ContBox10 .title h3 span.font03,
  #PageCar #Main #ContBox11 .title h3 span.font03,
  #PageCar #Main #ContBox12 .title h3 span.font03{
    /*letter-spacing: -0.05em;*/
  }

  /* h4 */
  #PageCar #Main h4{
    margin:0 0 10px 0;
    font-size:20px;
    line-height:1;
  }

  #PageCar #Main h4:last-of-type{
    margin:0 0 30px 0;
  }

  /* h5 */
  #PageCar #Main h5{
    margin:30px 0 48px;
    font-size:16px;
    font-weight:bold;
    text-align:left;
    border-bottom:3px solid #E7E7E7;
  }

  /* .cost */
  #PageCar #Main p.cost{
    box-sizing:border-box;
    position:relative;
    z-index:10;
    margin-bottom: 14px;
    padding-top:10px;
    height:85px;
    font-size:26px;
    font-weight:bold;
  }

  #PageCar #Main p.cost span{
    font-size:40px;
  }

  #PageCar #Main p.cost span.font03{
    position:relative;
    top:2px;
  }

  #PageCar #Main p.cost img{
    position:relative;
    top:-17px;
    margin:0 15px 0 15px;
    width:19px;
    height:auto;
  }

  /* .car_box */
  /*#PageCar #Main .car_box_warp:after{
    content: "";
    display: block;
    clear: both;
  }*/

  #PageCar #Main .car_box_warp.first{
    margin-bottom:82px;
  }


  #PageCar #Main .car_box{
    box-sizing:border-box;
    margin-top: 32px;
    margin-right:33px;
    float:left;
    width:310px;
  }
  #PageCar #Main .car_box:nth-of-type(1),
  #PageCar #Main .car_box:nth-of-type(2),
  #PageCar #Main .car_box:nth-of-type(3){
    margin-top: 0;
  }
  #PageCar #Main .car_box:nth-of-type(3n){
    margin-right: 0;
  }
  #PageCar #Main .car_box:nth-of-type(3n+1){
    clear: both;
  }

  #PageCar #Main .car_box_warp .car_box.top{
    margin-bottom: 124px;
  }

  #PageCar #Main .car_box.none{
    margin-top:18px;
  }

  #PageCar #Main .last_right{
      margin-right:0;
  }
  #PageCar #Main .car_box img.sp_img{
    display:none;
  }
  #PageCar #Main .car_box table{
    width:100%;
    border-top:1px solid #E7E7E7;
    border-bottom:1px solid #E7E7E7;
  }

  #PageCar #Main .car_box table th,
  #PageCar #Main .car_box table td{
    padding: 8px 0 10px;
    vertical-align: top;
    font-size:14px;
  }

  #PageCar #Main .car_box table th{
    width:80px;
  }
  #PageCar #Main .car_box table td .lastColorList{
      margin-top: 12px;
  }
  #PageCar #Main .car_box table .colorList+td{
    vertical-align: middle;
    padding: 14px 0;
  }
  /*　#MainTitleWarp
  -------------------------------------*/
  #PageCar #MainTitleWarp h2{
    background-size:64px auto;
  }


  /*　#ContBox01
  -------------------------------------*/
  #PageCar #Main #ContBox01{
    margin-bottom: 0;
  }
  #PageCar #Main #ContBox01 .innerBasic{
    padding-top: 50px;
    padding-bottom: 76px;
  }
  #PageCar #Main #ContBox01 ul{
    margin-bottom: 11px;
    text-align: center;
    font-size: 0;
  }
  #PageCar #Main #ContBox01 ul li{
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 20px;
    margin-left: 10px;
    width: 235px;
    line-height:1;
  }
  #PageCar #Main #ContBox01 ul li:nth-of-type(4n){
    margin-right: 0;
  }
  #PageCar #Main #ContBox01 ul li:nth-of-type(4n+1){
    margin-left: 0;
  }
  #PageCar #Main #ContBox01 ul li:last-of-type{
    margin-right:0px;
  }

  #PageCar #Main #ContBox01 ul li a{
    padding-top:10px;
    background-size:39px auto;
  }

  #PageCar #Main #ContBox01 ul li a:hover{
    opacity:0.7;
  }
  #PageCar #Main #ContBox01 .annotation{
    font-size: 14px;
    margin: 0;
    text-align: center;
  }
  #PageCar #Main .car_box figure {
    position: relative;
    margin-bottom: 22px;
  }
  #PageCar #Main .car_box figure::after {
    width: 89px;
    height: 69px;
    background-size: 89px auto;
  }
  #PageCar #Main .car_box table .fuel td {
    position: relative;
  }
  #PageCar #Main .priceImg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 89px;
    height: 69px;
  }
  #PageCar #Main #ContBox05{

  }
  #PageCar #Main #ContBox04 p.cost,
  #PageCar #Main #ContBox06 p.cost{
    margin-bottom:43px;
  }
}

@media screen and (max-width: 767px) {
  #PageCar #Main .contBox{
    margin-top: 0;
    padding-top: 0;
  }
  #PageCar #Main .contBox .innerBasic{
    padding-bottom: 15px;
  }
  #PageCar #Main #ContBox01{
    padding-top: 28px;
    padding-bottom: 25px;
  }
  #PageCar #Main #ContBox01 .innerBasic{
    padding-top: 0;
    padding-bottom: 0;
  }
  #PageCar #Main #ContBox01 ul{
    margin-bottom: 6px;
  }
  #PageCar #Main #ContBox01 ul::after{
    content:"";
    display:block;
    clear:both;
  }
  #PageCar #Main #ContBox01 ul li{
    float: left;
    box-sizing: border-box;
    width: 50%;
  }
  #PageCar #Main #ContBox01 ul li:nth-of-type(2n){
    padding-left: 5px;
  }
  #PageCar #Main #ContBox01 ul li:nth-of-type(2n+1){
    clear: both;
    padding-right: 5px;
  }
  #PageCar #Main #ContBox01 ul li a{
    padding-top: 2px;
    padding-right: 16px;
    background-size:31px auto;
    line-height: 1.1;
    font-size: 14px;
  }
  #PageCar #Main #ContBox01 ul li a .font03{
    letter-spacing: -0.01em;
  }
  #PageCar #Main p.annotation{
    margin-top: 0;
    text-align: center;
    font-size: 15px;
  }

  /* .title */
  #PageCar #Main .title{
      margin-bottom:20px;
      padding-top:12px;
      /*height:105px;*/
      background:#CA2432 url(../img/contents/car/sp_title_bk.png) no-repeat center top;
      background-size:325px auto;
  }
  #PageCar #Main #ContBox06 .title, 
  #PageCar #Main #ContBox04 .title{
    background-size:345px auto;
  }
  #PageCar #Main .title p{
      font-size:14px;
      text-align:center;
      line-height:1.1;
      letter-spacing: 0.8px;
  }

  #PageCar #Main .title h3{
      margin-top:-8px;
      font-size:24px;
      text-align:center;
      line-height:1;
      letter-spacing:1.5px;
      font-weight:bold;
  }
  /*#PageCar #Main .title h3.standard::after{
    content: "標準車";
    border: 1px solid #e59298;
    border-radius: 2px;
    font-size: 12px;
    letter-spacing: 1px;
    display: block;
    font-weight: normal;
    width: 54px;
    padding: 3px 0px;
    text-align: center;
    margin: 7px auto 0;
  }
  #PageCar #Main .title h3.custom::after{
    content: "カスタム";
    border: 1px solid #e59298;
    border-radius: 2px;
    font-size: 12px;
    letter-spacing: -0.5px;
    display: block;
    font-weight: normal;
    width: 54px;
    padding: 3px 4px;
    text-align: center;
    margin: 7px auto 0;
  }*/
  #PageCar #Main .title h3 span{
      color:#FFFC01;
  }

  #PageCar #Main .title h3 span.font03{
      position:relative;
      top:2px;
      font-size:40px;
  }

  /* h4 */
  #PageCar #Main h4{
      margin:0 0 8px 0;
      font-size:17px;
      line-height:1.3;
  }

  #PageCar #Main h4:last-of-type{
      margin:0 0 20px 0;
  }

  /* h5 */
  #PageCar #Main h5{
      font-size:16px;
      font-weight:bold;
      border-bottom:3px solid #E7E7E7;
  }

  /* .cost */
  #PageCar #Main p.cost{
      margin-bottom: 20px;
      padding:0 10px;
      font-size:20px;
      font-weight: bold;
  }
  #PageCar #Main .cost.centerCost{
    margin-top: 25px;
  }
  #PageCar #Main p.cost span{
      font-size:30px;
  }

  #PageCar #Main p.cost img{
      position:relative;
      top:-10px;
      margin:0 10px 0 10px;
      width:15px;
      height:auto;
  }

  #PageCar #Main figure {
    margin-bottom: 15px;
    text-align: center;
    position: relative;
  }
  #PageCar #Main figure img {
    width: 155px;
  }
  #PageCar #Main figure .priceImg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 67px;
    height: 52px;
  }
  /* .car_box */
  #PageCar #Main .car_box:after{
      content: "";  /* 新しい要素を作る */
      display: block;  /* ブロックレベル要素に */
      clear: both;
  }

  #PageCar #Main .car_box{
      box-sizing: border-box;
      margin: 0 auto 30px;
      padding: 0;
  }

  #PageCar #Main .car_box:last-of-type{
      margin-bottom: 0;
  }

  #PageCar #Main .car_box figure::after {
    width: 67px;
    height: 52px;
    background-size: 67px auto;
  }

  /**/
  #PageCar #Main .car_box{
      width:100%;
  }

  #PageCar #Main .car_box .about{
      box-sizing:border-box;
      width:100%;
      position: relative;
  }

  #PageCar #Main  .price {
    position: absolute;
    left: 10px;
    bottom: 0;
    background: #ca2431;
    width: 133px;
    color: #fff;
    font-size: 15px;
    text-align: center;
  }
  #PageCar #Main .price .text01 {
    color: #fffc00;
    font-size: 18px;
    font-weight: bold;
  }
  #PageCar #Main .price .text02 {
    font-size: 15px;
    font-weight: normal;
  }
  #PageCar #Main .car_box .about table{
      box-sizing:border-box;
      width:100%;
  }
  #PageCar #Main .car_box .about table tr{
    border-top: 1px solid #E7E7E7;
  }
  #PageCar #Main .car_box table th,
  #PageCar #Main .car_box table td{
    font-size:15px;
    position: relative;
  }

  #PageCar #Main .car_box table th.grade,
  #PageCar #Main .car_box table th.kind{
      padding: 8px 0;
  }
  #PageCar #Main .car_box table th.kind.longName{
    padding: 10px 0;
  }
  #PageCar #Main .car_box table .colorList{
    padding-top: 8px;
  }
  #PageCar #Main .car_box table .colorList + td {
    vertical-align: middle;
    padding: 15px 0;
  }
  #PageCar #Main .car_box table .colorList + td .lastColorList {
    margin-top: 12px;
  }
  #PageCar #Main .car_box .about table th{
      width:80px;
  }

  /*　#MainTitleWarp
  -------------------------------------*/
  #PageCar #MainTitleWarp h2{
      padding-top:43px;
      background-size:48px auto;
  }

  /*　#ContBox02
  -------------------------------------*/
  #PageCar #Main .about img{
    display: none;
  }


  #PageCar #Main #ContBox06{
    padding-top: 8px;
  }
}


/*####################　PageAbout　####################*/
/*　#MainTitleWarp
-------------------------------------*/
#PageAbout #MainTitleWarp h2{
  background-image:url(../img/contents/about/main_bk.png);
}

/*　#ContBox01
-------------------------------------*/
#PageAbout #Main #ContBox01 p{
  font-weight:bold;
  text-align:center;
}

#PageAbout #Main #ContBox01 h3{
  text-align:center;
  color:#da2634;
  font-weight:bold;
  line-height:1.4;
}

/*　#ContBox02
-------------------------------------*/
#PageAbout #Main #ContBox02{
  border-top:3px solid #D0D9E8;
}
#PageAbout #Main #ContBox02 .title {
  line-height:1;
  position:relative;
}

#PageAbout #Main #ContBox02 .title h3{
  font-weight:bold;
  text-align:center;
}

#PageAbout #Main #ContBox02 .title:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 18px;
  margin-left: -18px;
}

#PageAbout #Main #ContBox02 .title h3 .font04{
  color:#da2634;
}

#PageAbout #Main #ContBox02 .title h3 .point{
  color:#0046b1;
}

#PageAbout #Main #ContBox02 .conBox{
  padding-top:30px;
  padding-bottom:30px;
  background-color:#033C95;
}

#PageAbout #Main #ContBox02 .conBox p{
  margin-bottom:20px;
  color:#fff;
  font-weight:bold;
}


#PageAbout #Main #ContBox02 .sub_box{
  box-sizing:border-box;
  background-color:#E6EFFE;
  float:left;
}


#PageAbout #Main #ContBox02 .sub_box h3{
  font-weight:bold;
  color:#0046b1;
  line-height:1.2;
}

#PageAbout #Main #ContBox02 .sub_box:first-of-type h3{
  background-image:url(../img/contents/about/ContBox02_img01.png);
}

#PageAbout #Main #ContBox02 .sub_box:nth-of-type(2) h3{
  background-image:url(../img/contents/about/ContBox02_img02.png);
}

#PageAbout #Main #ContBox02 .sub_box:nth-of-type(3) h3{
  background-image:url(../img/contents/about/ContBox02_img03.png);
}

#PageAbout #Main #ContBox02 .sub_box:nth-of-type(4) h3{
  background-image:url(../img/contents/about/ContBox02_img04.png);
}

#PageAbout #Main #ContBox02 .sub_box:nth-of-type(5) h3{
  background-image:url(../img/contents/about/ContBox02_img05.png);
}

#PageAbout #Main #ContBox02 .sub_box:nth-of-type(6) h3{
  background-image:url(../img/contents/about/ContBox02_img06.png);
}

#PageAbout #Main #ContBox02 .innerBasic_box:after{
  content: "";  /* 新しい要素を作る */
  display: block;  /* ブロックレベル要素に */
  clear: both;
}

/*　#ContBox03
-------------------------------------*/
#PageAbout #Main #ContBox03 .box01 p{
  font-size:16px;
  color:#755a30;
  font-weight:bold;
}

#PageAbout #Main #ContBox03 .box01 ul,
#PageAbout #Main #ContBox03 .box02 ul{
  display:none;
}

/*　#ContBox04
-------------------------------------*/
#PageAbout #Main #ContBox04{
  padding-top:30px;
}

#PageAbout #Main #ContBox04 .sub_box{
  box-sizing:border-box;
  border-radius:6px;
  -moz-border-radius:6px;
  -webkit-border-radius:6px;
  border:3px solid #e3e9f0;
}

#PageAbout #Main #ContBox04 .sub_box h4{
  font-weight:bold;
  color:#0046b1;
  text-align:center;
}

#PageAbout #Main #ContBox04 .sub_box p{
  font-size:16px;
  text-align:center;
  line-height:1.4;
}

#PageAbout #Main #ContBox04 .sub_box p span{
  position:relative;
  top:-2px;
  font-size:12px;
  color:#888;
}

/*　#ContBox05
-------------------------------------*/
#PageAbout #Main #ContBox05{
  padding-top:40px;
}

#PageAbout #Main #ContBox05 p{
  text-align:center;
}

#PageAbout #Main #ContBox05 h3{
    margin-bottom:20px;
    font-weight:bold;
    line-height:1;
}

#PageAbout #Main #ContBox05 .subbox{
  border-radius:6px;
  -moz-border-radius:6px;
  -webkit-border-radius:6px;
}

#PageAbout #Main #ContBox05 .subbox:first-of-type{
  border:4px solid #033C95;
}

#PageAbout #Main #ContBox05 .subbox:nth-of-type(2){
  border:4px solid #009276;
}

#PageAbout #Main #ContBox05 .subbox .title{
  box-sizing:border-box;
  color:#fff;
}

#PageAbout #Main #ContBox05 .subbox:first-of-type .title{
  background-color:#033C95;
}

#PageAbout #Main #ContBox05 .subbox:nth-of-type(2) .title{
  background-color:#009276;
}

#PageAbout #Main #ContBox05 .subbox .title h4{
  text-align:center;
  font-weight:bold;
}

#PageAbout #Main #ContBox05 .subbox table th.monthcell{
    vertical-align:bottom;
}

#PageAbout #Main #ContBox05 .subbox table th.support{
  color:#023c95;
  font-weight:bold;
  text-align:center;
}

#PageAbout #Main #ContBox05 .subbox:first-of-type table th.support{
  color:#023c95;
}

#PageAbout #Main #ContBox05 .subbox:nth-of-type(2) table th.support{
  color:#009276;
}

#PageAbout #Main #ContBox05 .subbox table th.support span{
  position:relative;
  top:3px;
}

#PageAbout #Main #ContBox05 .subbox table td{
  padding:5px 0;
  text-align:center;
  font-weight:bold;
}

#PageAbout #Main #ContBox05 .subbox table tr.price{
  border-bottom:1px solid #E7E7E7;
}


@media print, screen and (min-width: 768px) {
  /*　#MainTitleWarp
  -------------------------------------*/
  #PageAbout #MainTitleWarp h2{
    background-size:56px auto;
  }

  /*　#ContBox01
  -------------------------------------*/
  #PageAbout #Main #ContBox01{
    margin:25px 0 80px;
  }

  #PageAbout #Main #ContBox01 p{
    font-size:20px;
    line-height:1.3;
  }

  #PageAbout #Main #ContBox01 h3{
    box-sizing:border-box;
    margin-bottom:25px;
    padding:15px 0 0 15px;
    height:138px; 
    background:url(../img/contents/about/ContBox01_h2_bk.png) no-repeat center top; 
    font-size:38px;
    letter-spacing:-0.03em;
  }

  #PageAbout #Main #ContBox01 h3 span.font04{
    position:relative;
    top:2px;
    font-size:66px;
    line-height:1;
  }

  /*　#ContBox02
  -------------------------------------*/
  #PageAbout #Main #ContBox02 .title {
    box-sizing:border-box;
    padding-top:5px;
    height:90px;
    background:#fff url(../img/contents/about/ContBox02_title.png) no-repeat center top;
  }

  #PageAbout #Main #ContBox02 .title h3{
    font-size:40px;
    line-height:1;
  }

  #PageAbout #Main #ContBox02 .title h3 .font04{
    position:relative;
    top:5px;
    font-size:66px;
  }

  #PageAbout #Main #ContBox02 .conBox{
    padding-top:43px;
  }

  #PageAbout #Main #ContBox02 .conBox .innerBasic > p{
    margin-bottom:30px;
    font-size:18px;
    letter-spacing:0.03em;
    line-height:32px;
  }

  #PageAbout #Main #ContBox02 .sub_box{
    margin:0 20px 20px 0;
    padding-left:25px;
    width:320px;
    height:138px;
    border-radius:20px;
    -moz-border-radius:20px;
    -webkit-border-radius:20px;
    display:table;
  }

  #PageAbout #Main #ContBox02 .last{
    margin-right:0;
  }

  #PageAbout #Main #ContBox02 .sub_box h3{
    box-sizing:border-box;
    padding-left:130px;
    height:103px;
    text-align:left;
    font-size:20px;
    background:no-repeat left center;
    display:table-cell;
    vertical-align:middle;
  }

  #PageAbout #Main #ContBox02 .sub_box:first-of-type h3{
    background-size:121px auto;
  }

  #PageAbout #Main #ContBox02 .sub_box:nth-of-type(2) h3{
    background-size:107px auto;
  }

  #PageAbout #Main #ContBox02 .sub_box:nth-of-type(3) h3{
    background-size:107px auto;
  }

  #PageAbout #Main #ContBox02 .sub_box:nth-of-type(4) h3{
    background-size:107px auto;
  }

  #PageAbout #Main #ContBox02 .sub_box:nth-of-type(5) h3{
    background-size:107px auto;
  }

  #PageAbout #Main #ContBox02 .sub_box:nth-of-type(6) h3{
    background-size:107px auto;
  }
  #PageAbout #Main #ContBox02 .moreBtn {
    margin: 15px auto 19px auto;
    width: 491px;
    height: 57px;
  }

  #PageAbout #Main #ContBox02 .moreBtn p {
      box-sizing: border-box;
      margin: 0;
      text-align: center;
      width: 491px;
      height: 57px;
      font-size: 16px;
      background-color: #012358;
      border-radius: 53px;
      -moz-border-radius: 53px;
      -webkit-border-radius: 53px;
      border: 2px solid #627FA9;
      position: relative;
    }
  /*#PageAbout #Main #ContBox02 .moreBtn p a {
    box-sizing: border-box;
    padding-left: 34px;
    padding-top: 10px;
    display: block;
    width: 100%;
    height: 100%;
    background: url(../img/contents/top/moreBtn_af.png) no-repeat right 23px center;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    font-weight: 300;
    }
  #PageAbout #Main #ContBox02 .moreBtn p a::before {
      content: "";
      background: url(../img/contents/top/moreBtn_car_03.png) no-repeat;
      position: absolute;
      top: 0;
      bottom: 2px;
      right: 78px;
      left: 0;
      width: 28px;
      height: 23px;
      margin: auto;
      padding-right: 140px;
    }*/
  /*　#ContBox03
  -------------------------------------*/
  #PageAbout #Main #ContBox03{
    padding-top: 18px;
  }
  #PageAbout #Main #ContBox03 h3.title01{
    padding-bottom:30px;
  }

  #PageAbout #Main #ContBox03 h3.title02{
    padding-bottom:18px;
    letter-spacing:-0.05em;
  }

  #PageAbout #Main #ContBox03 .box01{
    margin-bottom:17px;
    height:311px;
    background:url(../img/contents/hikaku_img01.png) no-repeat center top;
  }

  #PageAbout #Main #ContBox03 .box01 p{
    position:relative;
    top:80px;
    left:200px;
  }

  #PageAbout #Main #ContBox03 .box02{
    margin-bottom:80px;
    height:105px;
    background:url(../img/contents/hikaku_img02.png) no-repeat center top;
  }

  #PageAbout #Main #ContBox03 img.sp_img{
    display:none;
  }


  /*　#ContBox04
  -------------------------------------*/
  #PageAbout #Main #ContBox04{
    padding-top:80px;
  }

  #PageAbout #Main #ContBox04 h3.title01{
    margin-bottom:30px;
  }

  #PageAbout #Main #ContBox04 .sub_box{
    margin-right:20px;
    padding-top:25px;
    width:235px;
    height:234px;
    float:left;
  }

  #PageAbout #Main #ContBox04 .sub_box:last-of-type{
    margin-right:0;
  }

  #PageAbout #Main #ContBox04 .sub_box h4{
    padding-bottom:80px;
    font-size:26px;
  }

  #PageAbout #Main #ContBox04 .sub_box:first-of-type h4{
    background:url(../img/contents/about/ContBox04_title01.png) no-repeat center 50px;
    background-size:151px auto;
  }

  #PageAbout #Main #ContBox04 .sub_box:nth-of-type(2) h4{
    background:url(../img/contents/about/ContBox04_title02.png) no-repeat center 55px;
    background-size:153px auto;
  }

  #PageAbout #Main #ContBox04 .sub_box:nth-of-type(3) h4{
    background:url(../img/contents/about/ContBox04_title03.png) no-repeat center 50px;
    background-size:210px auto;
  }

  #PageAbout #Main #ContBox04 .sub_box:nth-of-type(4) h4{
    background:url(../img/contents/about/ContBox04_title04.png) no-repeat center 55px;
    background-size:194px auto;
  }

  #PageAbout #Main #ContBox04 .sub_box:nth-of-type(2) p{
    letter-spacing:-0.03em;
  }

  /*　#ContBox05
  -------------------------------------*/
  #PageAbout #Main #ContBox05{
    margin-top:80px;
    padding-top:80px;
  }

  #PageAbout #Main #ContBox05 h3.title01{
    line-height:1.4;
  }


  #PageAbout #Main #ContBox05 p{
    margin-bottom:0;
    font-size:18px;
  }

  #PageAbout #Main #ContBox05 p:last-of-type{
    margin-bottom:40px;
  }

  #PageAbout #Main #ContBox05 .subbox{
    width:478px;
    height:386px;
    float:left;
  }

  #PageAbout #Main #ContBox05 .subbox:first-of-type{
    margin-right:20px;
  }

  #PageAbout #Main #ContBox05 .subbox .title{
    padding:15px 0;
    height:100px;
  }

  #PageAbout #Main #ContBox05 .subbox .title h4{
    font-size:26px;
  }

  #PageAbout #Main #ContBox05 .subbox .title p{
    font-size:18px;
  }

  #PageAbout #Main #ContBox05 .subbox table{
    margin:30px auto;
    width:430px;
  }

  #PageAbout #Main #ContBox05 .subbox table th.support{
    font-size:16px;
  }

  #PageAbout #Main #ContBox05 .subbox table td{
    font-size:16px;
  }

  #PageAbout #Main #ContBox05 .subbox table td span{
    font-size:30px;
  }


  #PageAbout #Main #ContBox05 .subbox table h5{
      box-sizing:border-box;
      position:relative;
      color:#fff;
      text-align:center;
  }

  #PageAbout #Main #ContBox05 .subbox table h5.month{
      top:2px;
      padding-top:20px;
      font-size:16px;
      height:60px;
      width:131px;
      border-radius: 7px 7px 0 0 / 7px 7px 0 0;
      -webkit-border-radius: 7px 7px 0 0 / 7px 7px 0 0;
      -moz-border-radius: 7px 7px 0 0 / 7px 7px 0 0;
  }

  #PageAbout #Main #ContBox05 .subbox table h5.ari{
      background-color:#033C95;
  }

  #PageAbout #Main #ContBox05 .subbox table h5.nashi{
      background-color:#009276;
  }

  #PageAbout #Main #ContBox05 .subbox table h5.standard,
  #PageAbout #Main #ContBox05 .subbox table h5.premium{
      top:3px;
      padding-top:7px;
      font-size:13px;
      height:30px;
      width:130px;
      letter-spacing:-0.1em;
      background:no-repeat center top;
      background-size:130px auto;
  }

  #PageAbout #Main #ContBox05 .subbox table h5.standard{
      background-image:url(../img/contents/about/ContBox05_title01.png);
  }

  #PageAbout #Main #ContBox05 .subbox table h5.premium{
      background-image:url(../img/contents/about/ContBox05_title02.png);
  }
  #PageAbout #Main #ContBox05 .moreBtn {
    margin: 0 auto 100px;
    width: 491px;
    height: 57px;
  }

  }

@media screen and (max-width: 767px) {
  #PageAbout .annotation {
    margin-top: 40px;
  }
  /*　#MainTitleWarp
  -------------------------------------*/
  #PageAbout #MainTitleWarp h2{
      background-size:43px auto;
  }

  /*　#ContBox01
  -------------------------------------*/
  #PageAbout #Main #ContBox01{
      padding:25px 0;
  }

  #PageAbout #Main #ContBox01 p{
      font-size:17px;
      line-height:1.3;
  }

  #PageAbout #Main #ContBox01 h3{
      box-sizing:border-box;
      margin-bottom:20px;
      /*padding-left:15px;*/
      background:url(../img/contents/about/sp_ContBox01_h2_bk.png) no-repeat center 25px;
      background-size:258px auto;
      font-size:24px;
      letter-spacing:-0.08em;
  }

  #PageAbout #Main #ContBox01 h3 span.font04{
      position:relative;
      top:2px;
      font-size:40px;
      line-height:1;  
  }

  #PageAbout #Main #ContBox01 p{
      font-size:17px;
      line-height:1.3;
      font-weight:bold;
      text-align:center;
  }

  /*　#ContBox02
  -------------------------------------*/
  #PageAbout #Main #ContBox02 .title{
      padding-bottom:8px;
      line-height:1;
      position: relative;
  }

  #PageAbout #Main #ContBox02 .title h3{
      font-size:24px;
      line-height:1.2;
      background:url(../img/contents/about/sp_ContBox02_title.png) no-repeat right top;
      background-size:52px auto;
  }

  #PageAbout #Main #ContBox02 .title h3 .font04{
      font-size:40px;
      color:#da2634;
  }

  #PageAbout #Main #ContBox02 .conBox p{
      margin-bottom:20px;
  }
  #PageAbout #Main #ContBox02 .conBox .moreBtn p {
    margin-bottom: 0;
    }
  #PageAbout #Main #ContBox02 .sub_box{
    margin-left:2%;
    margin-bottom:9px;
    /*width:140px;*/
    width:48%;
    height:149px;
    border-radius:13px;
    -moz-border-radius:13px;
    -webkit-border-radius:13px;
    float:left;
  }

  #PageAbout #Main #ContBox02 .last_sp{
      margin-right:0;
  }

  #PageAbout #Main #ContBox02 .sub_box h3{
      text-align:center;
      margin-top:15px;
      font-size:17px;
      line-height:1.2;
      background:no-repeat center top;
  }

  #PageAbout #Main #ContBox02 .sub_box:first-of-type h3{
      padding-top:80px;
      background-size:90px auto;
  }

  #PageAbout #Main #ContBox02 .sub_box:nth-of-type(2) h3{
      padding-top:95px;
      background-size:82px auto;
  }

  #PageAbout #Main #ContBox02 .sub_box:nth-of-type(3) h3{
      padding-top:95px;
      background-size:82px auto;
  }

  #PageAbout #Main #ContBox02 .sub_box:nth-of-type(4) h3{
      padding-top:85px;
      background-size:82px auto;
  }

  #PageAbout #Main #ContBox02 .sub_box:nth-of-type(5) h3{
      padding-top:95px;
      background-size:82px auto;
  }

  #PageAbout #Main #ContBox02 .sub_box:nth-of-type(6) h3{
      padding-top:95px;
      background-size:82px auto;
  }

  /*　#ContBox03
  -------------------------------------*/
  #PageAbout #Main #ContBox03 h3.title01{
      margin-bottom:15px;
  }

  #PageAbout #Main #ContBox03 h3.title02{
      margin-bottom:10px;
  }

  #PageAbout #Main #ContBox03 .box01_Warp{
    background:url(../img/contents/sp_hikaku_img01_bk.png) repeat-x left top;
    background-size:1px auto;
  }

  #PageAbout #Main #ContBox03 .box01{
      position:relative;
      margin-bottom:5px;
      height:413px;
      background:url(../img/contents/sp_hikaku_img01.png) no-repeat center top;
      background-size:290px auto;
  }

  #PageAbout #Main #ContBox03 .box01 p{
    position:absolute;
      top:50px;
      left:135px;
  }

  #PageAbout #Main #ContBox03 .box02{
      margin-bottom:40px;
  }



  /*　#ContBox04
  -------------------------------------*/
  #PageAbout #Main #ContBox04 {
      padding-bottom:30px;
  }

  #PageAbout #Main #ContBox04 h3.title01{
      margin-bottom:15px;
  }

  #PageAbout #Main #ContBox04 .sub_box{
      margin-bottom:10px;
      padding-top:14px;
      height:152px;
      border-radius:13px;
  }

  #PageAbout #Main #ContBox04 .sub_box:nth-of-type(3) {
      height:168px;
  }

  #PageAbout #Main #ContBox04 .sub_box h4{
      padding-bottom:46px;
      font-size:19px;
      background:no-repeat center 32px;
  }

  #PageAbout #Main #ContBox04 .sub_box:first-of-type h4{
      background-image:url(../img/contents/about/ContBox04_title01.png);
      background-size:112px auto;
  }

  #PageAbout #Main #ContBox04 .sub_box:nth-of-type(2) h4{
      background-image:url(../img/contents/about/ContBox04_title02.png);
      background-size:115px auto;
  }

  #PageAbout #Main #ContBox04 .sub_box:nth-of-type(3) h4{
      background-image:url(../img/contents/about/ContBox04_title03.png);
      background-size:157px auto;
  }

  #PageAbout #Main #ContBox04 .sub_box:nth-of-type(4) h4{
      background-image:url(../img/contents/about/ContBox04_title04.png);
      background-size:146px auto;
  }

  /*　#ContBox05
  -------------------------------------*/
  #PageAbout #Main #ContBox05 h3{
      font-size:24px;
      line-height:1.2;
  }

  #PageAbout #Main #ContBox05 .subbox{
      margin-bottom:11px;
  }

  #PageAbout #Main #ContBox05 .subbox:first-of-type{
      margin-top:20px;
  }

  #PageAbout #Main #ContBox05 .subbox .title{
      padding:8px 0;
      height:69px;
  }

  #PageAbout #Main #ContBox05 .subbox .title h4{
      font-size:20px;
  }

  #PageAbout #Main #ContBox05 .subbox .title p{
      font-size:14px;
  }

  #PageAbout #Main #ContBox05 .subbox table{
      margin:20px auto;
      width:250px;
  }

  #PageAbout #Main #ContBox05 .subbox table td{
      font-size:9px;
      font-weight:bold;
  }

  #PageAbout #Main #ContBox05 .subbox table td span{
      font-size:20px;
  }

  #PageAbout #Main #ContBox05 .subbox table th.support{
      position:relative;
      top:-2px;
      font-size:10px;
      letter-spacing:-0.03em;
  }

  #PageAbout #Main #ContBox05 .subbox table h5{
      box-sizing:border-box;
      position:relative;
      color:#fff;
      text-align:center;
      font-weight:bold;
  }

  #PageAbout #Main #ContBox05 .subbox table h5.month{
      top:2px;
      padding-top:8px;
      font-size:16px;
      height:40px;
      width:85px;
      border-radius: 5px 5px 0 0 / 5px 5px 0 0;
      -webkit-border-radius: 5px 5px 0 0 / 5px 5px 0 0;
      -moz-border-radius: 5px 5px 0 0 / 5px 5px 0 0;
  }

  #PageAbout #Main #ContBox05 .subbox table h5.ari{
      background-color:#033C95;
  }

  #PageAbout #Main #ContBox05 .subbox table h5.nashi{
      background-color:#009276;
  }

  #PageAbout #Main #ContBox05 .subbox table h5.standard,
  #PageAbout #Main #ContBox05 .subbox table h5.premium{
      top:3px;
      padding-top:3px;
      font-size:13px;
      height:25px;
      width:75px;
      letter-spacing:-0.1em;
      background:no-repeat center top;
      background-size:75px auto;
  }

  #PageAbout #Main #ContBox05 .subbox table h5.standard{
      margin-left:5px;
      background-image:url(../img/contents/about/ContBox05_title01.png);
  }

  #PageAbout #Main #ContBox05 .subbox table h5.premium{
      background-image:url(../img/contents/about/ContBox05_title02.png);
  }
  #PageAbout #Main #ContBox05 .moreBtn p {
    margin-top: 30px;
  }
}

/*####################　PageReason　####################*/
/*　#MainTitleWarp
-------------------------------------*/
#PageReason #MainTitleWarp h2{
  background-image:url(../img/contents/reason/main_bk.png);
}

/*　#ContBox01
-------------------------------------*/
#PageReason #Main #ContBox01{
  box-sizing:border-box;
  text-indent:-9999px;
}


/*　#ContBox02
-------------------------------------*/
#PageReason #Main #ContBox02 .title:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 18px;
  margin-left: -18px;
}

#PageReason #Main #ContBox02 .title h3 .font04{
  color:#da2634;
}

#PageReason #Main #ContBox02 .title h3 .point{
  color:#0045B4;
}

#PageReason #Main #ContBox02 .conBox{
  padding-top:30px;
  padding-bottom:30px;
  background-color:#033C95;
}


/*　#ContBox04
-------------------------------------*/
#PageReason #Main #ContBox04 p.title{
  box-sizing:border-box;
  text-align:center;
  background:url(../img/contents/top/sp_ContBox04_ribbon.png) no-repeat center center;
  color:#fff;
  line-height:1;
}

#PageReason #Main #ContBox04 .subbox:first-of-type .title{
  background-color:#11B3C0;
}

#PageReason #Main #ContBox04 .subbox:nth-of-type(2) .title{
  background-color:#BCA734;
}

#PageReason #Main #ContBox04 .subbox .title h4{
  margin-top:-7px;
  text-align:center;
  line-height:1;
}

#PageReason #Main #ContBox04 .subbox ul li:before{
  font-size:11px;
    margin-right: 5px;
  position: relative;
}

#PageReason #Main #ContBox04 .subbox:first-of-type ul li:before{
  content: url("../img/contents/top/ContBox04_listicon01.png");
}

#PageReason #Main #ContBox04 .subbox:nth-of-type(2) ul li:before{
  content: url("../img/contents/top/ContBox04_listicon02.png");
}

#PageReason #Main #ContBox04 .maintenance ul li:before{
  font-size:11px;
  content: "・";
    margin-right: 5px;
  position: relative;
     top: 0;
}

#PageReason #Main #ContBox04 .info p{
  display:table-cell;
  vertical-align:middle;
  padding-left:130px;
  letter-spacing:-0.03em;
}


@media print, screen and (min-width: 768px) {
/*　#MainTitleWarp
-------------------------------------*/
#PageReason #MainTitleWarp h2{
  background-size:58px auto;
}

/*　#ContBox01
-------------------------------------*/
#PageReason #Main #ContBox01{
  padding-top:70px;
  background:#FFF9BB url(../img/contents/reason/ContBox01_bk.png) no-repeat center top;
  height:334px;
}

#PageReason #Main #ContBox01 h3{
  margin-top:5px;
  background:url(../img/contents/reason/ContBox01_txt_02.png) no-repeat center top;
  height:166px;
}

/*　#ContBox02
-------------------------------------*/
#PageReason #Main #ContBox02 {
  padding-top:6px;
  border-top:3px solid #D0D9E8;
}

#PageReason #Main #ContBox02 .title{
  line-height:1;
  position: relative;
  height:83px;
}

#PageReason #Main #ContBox02 .title h3{
  padding-left:30px;
  margin:0 auto;
  font-size:40px;
  height:93px;
  font-weight:bold;
  text-align:center;
  letter-spacing:0.02em;
}

#PageReason #Main #ContBox02 .title h3 .font04{
  font-size:66px;
}

#PageReason #Main #ContBox02 .title h3 .font04:first-of-type{
  position:relative;
  top:3px;
}

#PageReason #Main #ContBox02 .conBox{
  padding-bottom:50px;
}

#PageReason #Main #ContBox02 .conBox .symbol01,
#PageReason #Main #ContBox02 .conBox .symbol02,
#PageReason #Main #ContBox02 .conBox .answer{
  margin-top:20px;
  float:left;
}

#PageReason #Main #ContBox02 .conBox .symbol01{
  width:236px;
  background:url(../img/contents/top/ContBox03_symbol01.png) no-repeat 190px center;
}

#PageReason #Main #ContBox02 .conBox .symbol02{
  width:219px;
  background:url(../img/contents/top/ContBox03_symbol02.png) no-repeat 190px center;
}

#PageReason #Main #ContBox02 .conBox .answer{
  padding:12px 0 0 16px;
  box-sizing:border-box;
  width:305px;
  height:138px;
}

#PageReason #Main #ContBox02 .conBox .subbox_warp .subbox{
  box-sizing:border-box;
  width:175px;
  height:138px;
  background-color:#E6EFFE;
  border-radius:19px;
  -moz-border-radius:19px;
  -webkit-border-radius:19px;
}

#PageReason #Main #ContBox02 .conBox .subbox_warp .subbox h4{
  box-sizing:border-box;
  padding-top:54px;
  background:no-repeat center 22px;
  color:#0046b1;
  font-size:20px;
  font-weight:bold;
}

#PageReason #Main #ContBox02 .conBox .subbox_warp .subbox h4.title01{
  background-image:url(../img/contents/top/ContBox03_icon03.png);
}

#PageReason #Main #ContBox02 .conBox .subbox_warp .subbox h4.title02{
  background-image:url(../img/contents/top/ContBox03_icon02.png);
}

#PageReason #Main #ContBox02 .conBox .subbox_warp .subbox h4.title03{
  background-image:url(../img/contents/top/ContBox03_icon04.png);
}

#PageReason #Main #ContBox02 .conBox .subbox_warp .subbox p{
  font-size:12px;
  text-align:center;
}

#PageReason #Main #ContBox02 .conBox p.txt,
#PageReason #Main #ContBox02 .conBox p.price{
  font-weight:bold;
}

#PageReason #Main #ContBox02 .conBox p.txt{
  color:#fff;
  font-size:26px;
  line-height:1.2;
}

#PageReason #Main #ContBox02 .conBox p.price{
  margin-top:-10px;
  color:#fffc00;
  font-size:44px;
  line-height:1;
}

#PageReason #Main #ContBox02 .conBox p.price .font04{
  font-size:56px;
}


/*　#ContBox03
-------------------------------------*/
#PageReason #Main #ContBox03 h3{
  margin-top:-30px;
    margin-bottom:30px;
}

#PageReason #Main #ContBox03 p.about{
    position:relative;
    left:175px;
    font-size:16px;
    color:#fff;
}


#PageReason #Main #ContBox03 p.cost span.font03{
    font-size:37px;
}


/* .box01 */
#PageReason #Main #ContBox03 .box01{
  margin-bottom:12px;
  height:40px;
  background:url(../img/contents/reason/ContBox02_img01.png) no-repeat left top;
}

#PageReason #Main #ContBox03 .box01 h4{
    position:relative;
    top:5px;
    left:65px;
    text-align:left;
    font-size:20px;
    color:#333333;
    font-weight:bold;
}

#PageReason #Main #ContBox03 .box01 p.about{
    top:-21px;
}

#PageReason #Main #ContBox03 .box01 p.cost{
    position:relative;
    top:-75px;
    left:830px;
    font-size:22px;
    font-weight:bold;
    color:#bbbbbb;
}

/* .box02 */
#PageReason #Main #ContBox03 .box02{
  height:64px;
  background:url(../img/contents/reason/ContBox02_img02.png) no-repeat left top;
}

#PageReason #Main #ContBox03 .box02 h4{
    display:none;
}

#PageReason #Main #ContBox03 .box02 p.about{
    top:20px;
}

#PageReason #Main #ContBox03 .box02 p.point{
    position:relative;
    top:-16px;
    left:695px;
    font-size:16px;
    color:#fff;
}

#PageReason #Main #ContBox03 .box02 p.cost{
    position:relative;
    top:-65px;
    left:750px;
    font-size:22px;
    font-weight:bold;
    color:#023c95;
}

/*　#ContBox04
-------------------------------------*/
#PageReason #Main #ContBox04{
  margin-top:80px;
  padding-top:65px;
}

#PageReason #Main #ContBox04 h3.title01{
  margin-bottom:40px;
}

#PageReason #Main #ContBox04 p.title{
  margin:0 auto;
  width:384px;
  height:44px;
  background:url(../img/contents/top/ContBox04_ribbon.png) no-repeat center top;
  text-indent:-9999px;
}

#PageReason #Main #ContBox04 h3{
  margin:11px 0 54px;
  font-size:40px;
  font-weight:bold;
  line-height:1;
}

#PageReason #Main #ContBox04 .subbox{
  margin-bottom:20px;
  width:482px;
  height:205px;
  border-radius:8px;
  -moz-border-radius:8px;
  -webkit-border-radius:8px;
  float:left;
}

#PageReason #Main #ContBox04 .subbox:first-of-type{
  margin-right:14px;
}

#PageReason #Main #ContBox04 .subbox:first-of-type{
  border:5px solid #11B3C0;
}

#PageReason #Main #ContBox04 .subbox:nth-of-type(2){
  border:5px solid #BCA734;
}

#PageReason #Main #ContBox04 .subbox .title{
  margin-bottom:15px;
  padding:5px 0 17px;
  background-color:#11B3C0;
  color:#fff;
}

#PageReason #Main #ContBox04 .subbox .title p{
  text-align:center;
  font-size:18px;
}

#PageReason #Main #ContBox04 .subbox .title h4{
  font-size:26px;
}

#PageReason #Main #ContBox04 .subbox .title h4 .font03{
  font-size:40px;
}

#PageReason #Main #ContBox04 .subbox .title h4 .tax{
  font-size:14px;
}

#PageReason #Main #ContBox04 .subbox .sp{
  display:none;
}

#PageReason #Main #ContBox04 .subbox ul{
  width:220px;
  float:left;
}

#PageReason #Main #ContBox04 .subbox ul:first-of-type{
  margin-left:25px;
}

#PageReason #Main #ContBox04 .subbox ul li{
  margin-bottom:6px;
  font-size:16px;
  text-align:left;
  font-weight:bold;
}

#PageReason #Main #ContBox04 .subbox ul li:before{
     top: -3px;
}

#PageReason #Main #ContBox04 .maintenance{
  box-sizing:border-box;
  padding:15px;
  height:900px;
  background-color:#EEF5FF;
  border:1px solid #DDE7F3;
}

#PageReason #Main #ContBox04 .maintenance h3{
  padding-left:30px;
  background:url(../img/contents/top/ContBox04_icon01.png) no-repeat center top;
  font-size:20px;
  color:#0046b1;
  line-height:1;
}

#PageReason #Main #ContBox04 .maintenance ul{
  margin-top:-40px;
}

#PageReason #Main #ContBox04 .maintenance .list:after{
  content: "";  /* 新しい要素を作る */
  display: block;  /* ブロックレベル要素に */
  clear: both;
}


#PageReason #Main #ContBox04 .maintenance ul li{
  float:left;
  text-align:left;
  font-size:14px;
  line-height:2;
  letter-spacing:-0.05em;
}

#PageReason #Main #ContBox04 .maintenance ul li.menu01{
  width:210px;
}

#PageReason #Main #ContBox04 .maintenance ul li.menu02{
  width:294px;
}

#PageReason #Main #ContBox04 .maintenance ul li.menu03{
  width:223px;
}

#PageReason #Main #ContBox04 .maintenance ul li.menu04{
  width:240px;
}

#PageReason #Main #ContBox04 .info_warp:after{
  content: "";  /* 新しい要素を作る */
  display: block;  /* ブロックレベル要素に */
  clear: both;
}

#PageReason #Main #ContBox04 .info_warp{
  margin-top:20px;
}

#PageReason #Main #ContBox04 .info{
  box-sizing:border-box;
  width:370px;
  height:149px;
  background:url(../img/contents/top/ContBox04_img01.png) no-repeat left 15px;
  display:table;
  float:left;
}

#PageReason #Main #ContBox04 .info p{
  font-size:14px;
}

#PageReason #Main #ContBox04 .maintenance h4{
    margin:30px 0 15px;
    padding-top:20px;
    font-size:18px;
    font-weight:bold;
    border-top:1px solid #CFDAEC;
    text-align:center;
}

#PageReason #Main #ContBox04 .graph{
  height:920px;
    background:url(../img/contents/reason/ContBox03_img01.png) no-repeat center top;
}

#PageReason #Main #ContBox04 .graph ul{
    display:none;
}

/*　#ContBox05
-------------------------------------*/
#PageReason #Main #ContBox05{
  margin-top:80px;
  padding-top:65px;
}

#PageReason #Main #ContBox05 h3.title01{
  margin-bottom:30px;
}

#PageReason #Main #ContBox05 .subbox:first-of-type{
  margin-bottom:40px;
}

#PageReason #Main #ContBox05 .subbox:after{
  content: "";  /* 新しい要素を作る */
  display: block;  /* ブロックレベル要素に */
  clear: both;
}

#PageReason #Main #ContBox05 .subbox img{
  width:541px;
  height:auto;
}

#PageReason #Main #ContBox05 .subbox:first-of-type img{
  float:left;
}

#PageReason #Main #ContBox05 .subbox:nth-of-type(2) img{
  float:right;
}

#PageReason #Main #ContBox05 .subbox:first-of-type .txt{
  float:right;
}

#PageReason #Main #ContBox05 .subbox .txt{
  padding-top:25px;
  text-align:left;
}

#PageReason #Main #ContBox05 .subbox .txt h3{
  margin-bottom:25px;
    color:#0046b1;
  font-size:24px;
    display:inline-block;
  font-weight:bold;
}

#PageReason #Main #ContBox05 .subbox .txt ul{
    margin-bottom:30px;
}

#PageReason #Main #ContBox05 .subbox .txt ul li{
  text-align:left;
  margin-bottom:10px;
    padding-left:20px;
    background:url(../img/contents/reason/ContBox04_icon.png) no-repeat left 5px;
    background-size:14px auto;
  font-size:18px;
    font-weight:bold;
  line-height:1.6;
}

#PageReason #Main #ContBox05 .subbox .txt ul li span{
    font-size:14px;
}

/*　#ContBox06
-------------------------------------*/
#PageReason #Main #ContBox06{
  margin-top:100px;
  padding-top:80px;
}

#PageReason #Main #ContBox06 .title p{
    margin:0 auto;
    width:305px;
    height:44px;
    background:url(../img/contents/reason/ContBox04_title.png) no-repeat center top;
    text-indent:-9999px;
}

#PageReason #Main #ContBox06 .title h3{
    margin-bottom:15px;
  font-size:40px;
    text-align:center;
    font-weight:bold;
}

#PageReason #Main #ContBox06 p.catch{
    font-size:17px;
    text-align:center;
}

#PageReason #Main #ContBox06 .conBox .symbol01{
  box-sizing:border-box;
  margin-top:20px;
  margin-bottom:10px;
  float:left;
  height:120px;
  width:265px;
  background:url(../img/contents/reason/ContBox06_symbol01.png) no-repeat 220px center;
  background-size:30px auto;
}

#PageReason #Main #ContBox06 .conBox .symbol01:nth-of-type(4),
#PageReason #Main #ContBox06 .conBox .symbol01:nth-of-type(7){
  box-sizing:border-box;
  margin-top:20px;
  float:left;
  height:120px;
  width:205px;
  background:none;
}

#PageReason #Main #ContBox06 .conBox .symbol01:nth-of-type(5){
  margin-left:130px;
}

#PageReason #Main #ContBox06 .conBox .subbox_warp .subbox{
  box-sizing:border-box;
  width:205px;
  height:120px;
  background-color:#E6EFFE;
  border-radius:19px;
  -moz-border-radius:19px;
  -webkit-border-radius:19px;
}


#PageReason #Main #ContBox06 .conBox .subbox_warp .subbox h4{
  box-sizing:border-box;
  padding-top:50px;
  color:#0046b1;
  font-size:20px;
  font-weight:bold;
  text-align:center;
}


#PageReason #Main #ContBox06 .conBox .subbox_warp .subbox h4.title01{
    background:url(../img/contents/reason/ContBox04_title01.png) no-repeat center 18px;
    background-size:34px auto;
}

#PageReason #Main #ContBox06 .conBox .subbox_warp .subbox h4.title02{
    background:url(../img/contents/reason/ContBox04_title02.png) no-repeat center 12px;
    background-size:42px auto;
}

#PageReason #Main #ContBox06 .conBox .subbox_warp .subbox h4.title03{
    background:url(../img/contents/reason/ContBox04_title03.png) no-repeat center 20px;
    background-size:63px auto;
}

#PageReason #Main #ContBox06 .conBox .subbox_warp .subbox h4.title04{
    background:url(../img/contents/reason/ContBox04_title04.png) no-repeat center 20px;
    background-size:50px auto;
}

#PageReason #Main #ContBox06 .conBox .subbox_warp .subbox h4.title05{
    background:url(../img/contents/reason/ContBox04_title05.png) no-repeat center 17px;
    background-size:39px auto;
}

#PageReason #Main #ContBox06 .conBox .subbox_warp .subbox h4.title06{
    background:url(../img/contents/reason/ContBox04_title06.png) no-repeat center 17px;
    background-size:35px auto;
}

#PageReason #Main #ContBox06 .conBox .subbox_warp .subbox h4.title07{
    background:url(../img/contents/reason/ContBox04_title07.png) no-repeat center 22px;
    background-size:36px auto;
}


#PageReason #Main #ContBox06 .conBox .subbox_warp .subbox p{
  font-size:12px;
  text-align:center;
}


}

@media screen and (max-width: 767px) {
/*　共通
-------------------------------------*/
#PageReason #Main #ContBox02 .conBox .subbox_warp:after,
#PageReason #Main #ContBox06 .conBox .subbox_warp:after{
    content: "";  /* 新しい要素を作る */
    display: block;  /* ブロックレベル要素に */
    clear: both;
}

#PageReason #Main #ContBox02 .conBox .symbol01{
    margin-bottom:8px;
    padding-bottom:14px;
    background:url(../img/contents/top/ContBox03_symbol01.png) no-repeat center bottom;
    background-size:22px auto;
}


#PageReason #Main #ContBox06 .conBox .symbol01{
    margin-bottom:8px;
    padding-bottom:14px;
    background:url(../img/contents/reason/ContBox06_symbol01.png) no-repeat center bottom;
    background-size:23px auto;
}


#PageReason #Main #ContBox02 .conBox .subbox_warp,
#PageReason #Main #ContBox06 .conBox .subbox_warp{
    margin-bottom:15px;
    background-color:#E6EFFE;
    border-radius:13px;
    -moz-border-radius:13px;
    -webkit-border-radius:13px;
}

#PageReason #Main #ContBox02 .conBox p.txt,
#PageReason #Main #ContBox02 .conBox p.price,
#PageReason #Main #ContBox06 .conBox p.txt,
#PageReason #Main #ContBox06 .conBox p.price{
    text-align:center;
    font-weight:bold;
    line-height:1;
}

#PageReason #Main #ContBox02 .conBox p.txt,
#PageReason #Main #ContBox06 .conBox p.txt{
    color:#fff;
    font-size:16px;
}

#PageReason #Main #ContBox02 .conBox p.price,
#PageReason #Main #ContBox06 .conBox p.price{
    margin-bottom:20px;
    color:#fffc00;
    font-size:28px;
}


/*　#MainTitleWarp
-------------------------------------*/
#PageReason #MainTitleWarp h2{
    background-size:43px auto;
}

/*　#ContBox01
-------------------------------------*/
#PageReason #Main #ContBox01{
    padding-top:17px;
    background:#FFF9BB url(../img/contents/reason/sp_ContBox01_bk.png) no-repeat center top;
    background-size:586px auto;
    height:181px;
}

#PageReason #Main #ContBox01 h3{
    margin-top:28px;
    background:url(../img/contents/reason/sp_ContBox01_txt_02.png) no-repeat center top;
    background-size:290px auto;
    height:103px;
}

/*　#ContBox02
-------------------------------------*/
#PageReason #Main #ContBox02{
    border-top:3px solid #D0D9E8;
}

#PageReason #Main #ContBox02 .title{
    padding:13px 0;
    background-color:#fff;
    position: relative;
}

#PageReason #Main #ContBox02 .title h3{
    font-size:24px;
    font-weight:bold;
    line-height:1.4;
    text-align:center;
}

#PageReason #Main #ContBox02 .title h3 .font04{
    font-size:40px;
}


#PageReason #Main #ContBox02 .conBox .subbox_warp .subbox{
    padding:20px 0 0 0;
    margin:0 auto;
    height:69px;
    width:290px;
}

#PageReason #Main #ContBox02 .conBox .subbox_warp .subbox h4{
    float:left;
    text-align:center;
    width:145px;
    background:no-repeat center 3px;
    color:#0046b1;
    font-size:17px;
    font-weight:bold;
}

#PageReason #Main #ContBox02 .conBox .subbox_warp .subbox p{
    box-sizing:border-box;
    padding:5px 15px 0 0;
    float:right;
    width:133px;
    letter-spacing:-0.05em;
    font-size:14px;
}

#PageReason #Main #ContBox02 .title .font04:first-of-type{
    position:relative;
    top:2px;
}

#PageReason #Main #ContBox02 .conBox .symbol02{
    margin-bottom:8px;
    padding-bottom:14px;
    background:url(../img/contents/top/sp_ContBox03_symbol02.png) no-repeat center bottom;
    background-size:12px auto;
}

#PageReason #Main #ContBox02 .conBox .subbox_warp .subbox h4.title01{
    padding-top:24px;
    background-image:url(../img/contents/top/sp_ContBox03_icon03.png) ;
    background-size:47px auto;
}

#PageReason #Main #ContBox02 .conBox .subbox_warp .subbox h4.title02{
    padding-top:25px;
    background-image:url(../img/contents/top/sp_ContBox03_icon02.png) ;
    background-size:26px auto;
}

#PageReason #Main #ContBox02 .conBox .subbox_warp .subbox h4.title03{
    padding-top:28px;
    background-image:url(../img/contents/top/sp_ContBox03_icon04.png) ;
    background-size:38px auto;
}

#PageReason #Main #ContBox02 .conBox p.price .font04{
    font-size:34px;
}



/*　#ContBox03
-------------------------------------*/
#PageReason #Main #ContBox03{
    padding-bottom:30px;
}

#PageReason #Main #ContBox03 h3{
    margin-bottom:30px;
}

#PageReason #Main #ContBox03 p.about{
    position:absolute;
    left:12px;
    font-size:16px;
    color:#fff;
}

#PageReason #Main #ContBox03 p.cost{
    position:absolute;
    font-size:22px;
    font-weight:bold;
}


#PageReason #Main #ContBox03 p.cost span.font03{
    font-size:37px;
}


/* .box01 */
#PageReason #Main #ContBox03 .box01{
    margin-bottom:10px;
    position:relative;
    background:url(../img/contents/reason/sp_ContBox02_img01.png) no-repeat center top;
    background-size:290px auto;
    height:110px;
}

#PageReason #Main #ContBox03 .box01 h4{
    position:absolute;
    left:65px;
    font-size:20px;
    color:#333333;
    font-weight:bold;
}

#PageReason #Main #ContBox03 .box01 p.about{
    top:45px;
}

#PageReason #Main #ContBox03 .box01 p.cost{
    top:-10px;
    left:150px;
    font-weight:bold;
    color:#bbbbbb;
}

/* .box02 */
#PageReason #Main #ContBox03 .box02{
    position:relative;
    background:url(../img/contents/reason/sp_ContBox02_img02.png) no-repeat center top;
    background-size:290px auto;
    height:110px;
}

#PageReason #Main #ContBox03 .box02 h4{
    display:none;
}

#PageReason #Main #ContBox03 .box02 p.about{
    top:45px;
}

#PageReason #Main #ContBox03 .box02 p.point{
    position:absolute;
    top:58px;
    left:215px;
    font-size:16px;
    color:#fff;
}

#PageReason #Main #ContBox03 .box02 p.cost{
    top:-10px;
    left:150px;
    color:#023c95;
}


/*　#ContBox04
-------------------------------------*/
#PageReason #Main #ContBox04{
    padding:30px 0 40px;
}

#PageReason #Main #ContBox04 p.title{
    margin:0 auto 5px;
    width:290px;
    height:34px;
    background:url(../img/contents/top/sp_ContBox04_ribbon.png) no-repeat left top;
    background-size:290px auto;
    text-indent:-9999px;
}

#PageReason #Main #ContBox04 h3{
    margin-bottom:20px;
    font-size:24px;
    font-weight:bold;
    line-height:1;
}

#PageReason #Main #ContBox04 .subbox{
    margin-bottom:11px;
    border-radius:6px;
    -moz-border-radius:6px;
    -webkit-border-radius:6px;
}

#PageReason #Main #ContBox04 .subbox:first-of-type{
    border:4px solid #11B3C0;
}

#PageReason #Main #ContBox04 .subbox:nth-of-type(2){
    border:4px solid #BCA734;
}

#PageReason #Main #ContBox04 .subbox .title{
    padding:10px 0;
    background-color:#11B3C0;
    color:#fff;
}

#PageReason #Main #ContBox04 .subbox .title p{
    text-align:center;
    font-size:13px;
}

#PageReason #Main #ContBox04 .subbox .title h4{
    font-size:20px;
}

#PageReason #Main #ContBox04 .subbox .title h4 .font03{
    font-size:34px;
}

#PageReason #Main #ContBox04 .subbox .title h4 .tax{
    font-size:10px;
}

#PageReason #Main #ContBox04 .subbox .pc{
    display:none;
}

#PageReason #Main #ContBox04 .subbox ul{
    padding:15px 0 15px 30px;
}

#PageReason #Main #ContBox04 .subbox ul.first{
    padding:15px 0 0 30px;
}

#PageReason #Main #ContBox04 .subbox ul.second{
    padding:0 0 15px 30px;
}

#PageReason #Main #ContBox04 .subbox ul li{
    text-align:left;
    font-weight:bold;
    line-height:2;
}

#PageReason #Main #ContBox04 .subbox ul li:before{
    top: -5px;
}

#PageReason #Main #ContBox04 .maintenance{
    padding:10px 10px 20px 10px;
    background-color:#EEF5FF;
    border:1px solid #DDE7F3;
}

#PageReason #Main #ContBox04 .maintenance h3{
    padding-top:20px;
    background:url(../img/contents/top/sp_ContBox04_icon01.png) no-repeat center top;
    font-size:20px;
    color:#0046b1;
    line-height:1.2;
    text-align:center;
}

#PageReason #Main #ContBox04 .maintenance ul{
    margin-top:-10px;
}


#PageReason #Main #ContBox04 .maintenance ul li{
    text-align:left;
    font-size:15px;
    line-height:1.6;
}

#PageReason #Main #ContBox04 info_warp{
    margin-top:10px;
}

#PageReason #Main #ContBox04 .info{
    margin-bottom:10px;
    box-sizing:border-box;
    height:119px;
    background:url(../img/contents/top/ContBox04_img01.png) no-repeat left top;
    display:table;
}

#PageReason #Main #ContBox04 .info p{
    font-size:15px;
}


#PageReason #Main #ContBox04 .info p{
    font-size:15px;
}

#PageReason #Main #ContBox04 .maintenance h4{
    margin:15px 0 10px;
    padding-top:15px;
    font-size:18px;
    font-weight:bold;
    border-top:1px solid #CFDAEC;
    text-align:center;
}

#PageReason #Main #ContBox04 p.sp_display{
    font-size:15px;
    text-align:center;
}

#PageReason #Main #ContBox04 .graph{
    background:url(../img/contents/reason/sp_ContBox03_img01.png) no-repeat center top;
    background-size:260px auto;
}

#PageReason #Main #ContBox04 .graph ul{
    display:none;
}


/*　#ContBox05
-------------------------------------*/
#PageReason #Main #ContBox05{
    padding-top:30px;
}

#PageReason #Main #ContBox05 h3.title01{
    margin-bottom:20px;
}

#PageReason #Main #ContBox05 .subbox img{
    margin-bottom:15px;
}

#PageReason #Main #ContBox05 .txt h3{
    margin-bottom:15px;
    color:#0046b1;
    font-size:18px;
    font-weight:bold;
}

#PageReason #Main #ContBox05 .txt h3{
    margin-bottom:15px;
    color:#0046b1;
    font-size:18px;
    font-weight:bold;
}

#PageReason #Main #ContBox05 .subbox:first-of-type .txt h3{
    width:177px;
}

#PageReason #Main #ContBox05 .subbox:nth-of-type(2) .txt h3{
    width:290px;
}

#PageReason #Main #ContBox05 .subbox .txt ul{
    margin-bottom:30px;
}

#PageReason #Main #ContBox05 .subbox .txt ul li{
    margin-bottom:10px;
    padding-left:20px;
    background:url(../img/contents/reason/ContBox04_icon.png) no-repeat left 5px;
    background-size:14px auto;
    font-size:16px;
    font-weight:bold;
    line-height:1.4;
}

#PageReason #Main #ContBox05 .subbox .txt ul li span{
    font-size:14px;
}


/*　#ContBox06
-------------------------------------*/
#PageReason #Main #ContBox06{
    padding-top:30px;
    border-top:1px solid #E7E7E7;
}

#PageReason #Main #ContBox06 .title p{
    margin:0 auto;
    width:290px;
    height:39px;
    background:url(../img/contents/reason/sp_ContBox04_title.png) no-repeat center top;
    background-size:290px auto;
    text-indent:-9999px;
}

#PageReason #Main #ContBox06 .title h3{
    margin-bottom:15px;
    font-size:24px;
    text-align:center;
    font-weight:bold;
}

#PageReason #Main #ContBox06 p.catch{
    font-size:17px;
    text-align:center;
}

#PageReason #Main #ContBox06 .conBox .subbox_warp .subbox{
    padding:10px 0;
    margin:0 auto;
    height:55px;
    width:290px;
}

#PageReason #Main #ContBox06 .conBox .subbox_warp .subbox h4{
    float:left;
    text-align:center;
    width:145px;
    background:no-repeat center 3px;
    color:#0046b1;
    font-size:17px;
    font-weight:bold;
}

#PageReason #Main #ContBox06 .conBox .subbox_warp .subbox p{
    box-sizing:border-box;
    padding:20px 15px 0 0;
    float:right;
    width:133px;
    letter-spacing:-0.05em;
    font-size:14px;
}

#PageReason #Main #ContBox06 .conBox .subbox_warp .subbox p.txt01{
    padding:7px 15px 0 0;
}

#PageReason #Main #ContBox06 .conBox .subbox_warp .subbox h4.title01{
    padding-top:30px;
    background-image:url(../img/contents/reason/ContBox04_title01.png) ;
    background-size:26px auto;
}

#PageReason #Main #ContBox06 .conBox .subbox_warp .subbox h4.title02{
    padding-top:35px;
    background-image:url(../img/contents/reason/ContBox04_title02.png) ;
    background-size:31px auto;
}

#PageReason #Main #ContBox06 .conBox .subbox_warp .subbox h4.title03{
    padding-top:30px;
    background-image:url(../img/contents/reason/ContBox04_title03.png) ;
    background-size:49px auto;
}

#PageReason #Main #ContBox06 .conBox .subbox_warp .subbox h4.title04{
    padding-top:30px;
    background-image:url(../img/contents/reason/ContBox04_title04.png) ;
    background-size:38px auto;
}

#PageReason #Main #ContBox06 .conBox .subbox_warp .subbox h4.title05{
    padding-top:30px;
    background-image:url(../img/contents/reason/ContBox04_title05.png) ;
    background-size:29px auto;
}

#PageReason #Main #ContBox06 .conBox .subbox_warp .subbox h4.title06{
    padding-top:30px;
    background-image:url(../img/contents/reason/ContBox04_title06.png) ;
    background-size:26px auto;
}

#PageReason #Main #ContBox06 .conBox .subbox_warp .subbox h4.title07{
    padding-top:30px;
    background-image:url(../img/contents/reason/ContBox04_title07.png) ;
    background-size:28px auto;
}


#PageReason #Main #ContBox06 .conBox .symbol01:last-of-type{
    margin-bottom:0;
    padding-bottom:0;
    background:none;
}

}


/*####################　PageComparison　####################*/
/*　#MainTitleWarp
-------------------------------------*/
#PageComparison #MainTitleWarp h2{
  background-image:url(../img/contents/comparison/main_bk.png) ;
}

/*　#ContBox01
-------------------------------------*/
#PageComparison #Main #ContBox01 h3{
  color:#da2634;
  font-weight:bold;
  text-align:center;    
}

#PageComparison #Main #ContBox01 p{
  text-align:center;
}

#PageComparison #Main #ContBox01 p span{
  font-weight:bold;
}

/*　#ContBox02
-------------------------------------*/
#PageComparison #Main #ContBox02 .title p,
#PageComparison #Main #ContBox03 .title p{
  text-indent:-9999px;
}

#PageComparison #Main #ContBox02 .title h3,
#PageComparison #Main #ContBox03 .title h3,
#PageComparison #Main #ContBox04 .title h3{
  font-weight:bold;
  text-align:center;
}

#PageComparison #Main #ContBox02 p.catch,
#PageComparison #Main #ContBox03 p.catch{
  text-align:center;
}


#PageComparison #Main #ContBox02 .subbox .title{
  color:#fff;
}

#PageComparison #Main #ContBox02 .box01 .title{
  background-color:#CAA765;
}

#PageComparison #Main #ContBox02 .box02 .title{
  background-color:#033C95;
}

#PageComparison #Main #ContBox02 .subbox .title h4{
  margin-top:-7px;
  text-align:center;
  line-height:1;
  font-weight:bold;
}

#PageComparison #Main #ContBox02 .subbox{
  border-radius:6px;
  -moz-border-radius:6px;
  -webkit-border-radius:6px;
}

#PageComparison #Main #ContBox02 .box01{
  border:4px solid #CAA765;
}

#PageComparison #Main #ContBox02 .box02{
  border:4px solid #033C95;
}

#PageComparison #Main #ContBox02 .subbox table tr:first-of-type{
  border-bottom:1px solid #E7E7E7;
}

#PageComparison #Main #ContBox02 .subbox table th,
#PageComparison #Main #ContBox02 .subbox table td{
  font-weight:bold;
}

#PageComparison #Main #ContBox02 .subbox table td.middle{
  vertical-align:middle;
}

#PageComparison #Main #ContBox02 .subbox table td.center{
  text-align:center;
}


#PageComparison #Main #ContBox02 .subbox table td span.price{
  margin-left:3px;
}

#PageComparison #Main #ContBox02 .subbox p.appeal{
  color:#da2634;
  font-weight:bold;
  letter-spacing:0.03em;
}

/*　#ContBox03
-------------------------------------*/
#PageComparison #Main #ContBox03 .box01 p{
  position:relative;
  font-size:16px;
  color:#755a30;
  font-weight:bold;
}

#PageComparison #Main #ContBox03 .box01 ul,
#PageComparison #Main #ContBox03 .box02 ul{
  display:none;
}

/*　#ContBox04
-------------------------------------*/
#PageComparison #Main #ContBox04 p.title{
  box-sizing:border-box;
  text-align:center;
  background:url(../img/contents/top/sp_ContBox04_ribbon.png) no-repeat center center;
  color:#fff;
  line-height:1;
}

#PageComparison #Main #ContBox04 .subbox .title h4{
  margin-top:-7px;
  text-align:center;
  line-height:1;
}

#PageComparison #Main #ContBox04 .subbox ul li:before{
  font-size:11px;
    margin-right: 5px;
  position: relative;
}

#PageComparison #Main #ContBox04 .box01 ul li:before{
  content: url("../img/contents/top/ContBox04_listicon01.png");
}

#PageComparison #Main #ContBox04 .box02 ul li:before{
  content: url("../img/contents/top/ContBox04_listicon02.png");
}

#PageComparison #Main #ContBox04 .maintenance ul li:before{
  font-size:11px;
  content: "・";
    margin-right: 5px;
  position: relative;
     top: 0;
}



@media print, screen and (min-width: 768px) {
/*　#MainTitleWarp
-------------------------------------*/
#PageComparison #MainTitleWarp h2{
  background-size:58px auto;
}


/*　#ContBox01
-------------------------------------*/
#PageComparison #Main #ContBox01 h3{
  height:140px;
  background:url(../img/contents/comparison/ContBox01_h2_bk.png) no-repeat center 20px;
  font-size:40px;
}

#PageComparison #Main #ContBox01 p{
  margin-top:-15px;
  font-size:14px;
}

/*　#ContBox02
-------------------------------------*/
#PageComparison #Main #ContBox02 {
  margin-top:80px;
  padding-top:80px;
}


#PageComparison #Main #ContBox02 .title p{
  margin:0 auto;
  width:305px;
  height:44px;
  background:url(../img/contents/comparison/ContBox01_title01.png) no-repeat center bottom;
}

#PageComparison #Main #ContBox02 .title h3,
#PageComparison #Main #ContBox03 .title h3,
#PageComparison #Main #ContBox04 .title h3{
  margin-bottom:15px;
  font-size:40px;
}

#PageComparison #Main #ContBox02 p.catch,
#PageComparison #Main #ContBox03 p.catch,
#PageComparison #Main #ContBox04 p.catch{
  font-size:18px;
  line-height:1;
}

#PageComparison #Main #ContBox02 p.catch:last-of-type,
#PageComparison #Main #ContBox03 p.catch:last-of-type,
#PageComparison #Main #ContBox04 p.catch:last-of-type{
  margin-bottom:30px;
}


#PageComparison #Main #ContBox02 .subbox{
  float:left;
  width:477px;
  height:276px;
}

#PageComparison #Main #ContBox02 .box01{
  margin-right:20px;
}

#PageComparison #Main #ContBox02 .subbox .title{
  box-sizing:border-box;
  padding:35px 0 15px;
  height:79px;
}

#PageComparison #Main #ContBox02 .subbox .title h4{
  font-size:26px;
}

#PageComparison #Main #ContBox02 .subbox table{
  margin:15px auto;
  width:430px;
  font-size:18px;
}

#PageComparison #Main #ContBox02 .subbox table th,
#PageComparison #Main #ContBox02 .subbox table td{
  padding:15px 0;
}
    
#PageComparison #Main #ContBox02 .subbox table th{
  width:300px;
}

#PageComparison #Main #ContBox02 .subbox table td{
  width:130px;
}


#PageComparison #Main #ContBox02 .subbox table td span.font03{
  font-size:28px;
}

#PageComparison #Main #ContBox02 .subbox table td span.price{
  font-size:16px;
}

#PageComparison #Main #ContBox02 .subbox p.appeal{
  margin-top:20px;
  padding-left:26px;
  font-size:24px;
}

/*　#ContBox03
-------------------------------------*/
#PageComparison #Main #ContBox03 {
  margin-top:80px;
  padding-top:80px;
}

#PageComparison #Main #ContBox03 .title p{
  margin:0 auto;
  width:305px;
  height:45px;
  background:url(../img/contents/comparison/ContBox01_title02.png) no-repeat center bottom;
}

#PageComparison #Main #ContBox03 p.catch:last-of-type{
  margin-bottom:40px;
}

#PageComparison #Main #ContBox03 .box01{
  margin-bottom:15px;
  height:311px;
  background:url(../img/contents/hikaku_img01.png) no-repeat center top;
}

#PageComparison #Main #ContBox03 .box01 p{
  top:80px;
  left:200px;
}

#PageComparison #Main #ContBox03 .box02{
  margin-top:15px;
  height:105px;
  background:url(../img/contents/hikaku_img02.png) no-repeat center top;
}

#PageComparison #Main #ContBox03 img.sp_img{
  display:none;
}

/*　#ContBox04
-------------------------------------*/
#PageComparison #Main #ContBox04 {
  margin-top:80px;
  padding-top:80px;
}

#PageComparison #Main #ContBox04 .title p.top{
  margin:0 auto;
  width:384px;
  height:45px;
  background:url(../img/contents/comparison/ContBox01_title03.png) no-repeat center bottom;
  text-indent:-9999px;
}

#PageComparison #Main #ContBox04 .subbox{
  margin-bottom:20px;
  width:482px;
  height:205px;
  border-radius:8px;
  -moz-border-radius:8px;
  -webkit-border-radius:8px;
  float:left;
}

#PageComparison #Main #ContBox04 .box01{
  margin-right:14px;
  border:5px solid #11B3C0;
}

#PageComparison #Main #ContBox04 .box02{
  border:5px solid #BCA734;
}

#PageComparison #Main #ContBox04 .box01 .title{
  margin-bottom:15px;
  padding:5px 0 17px;
  background-color:#11B3C0;
  color:#fff;
}

#PageComparison #Main #ContBox04 .box02 .title{
  margin-bottom:15px;
  padding:5px 0 17px;
  background-color:#BCA734;
  color:#fff;
}

#PageComparison #Main #ContBox04 .subbox .title p{
  text-align:center;
  font-size:18px;
}

#PageComparison #Main #ContBox04 .subbox .title h4{
  font-size:26px;
}

#PageComparison #Main #ContBox04 .subbox .title h4 .font03{
  font-size:40px;
}

#PageComparison #Main #ContBox04 .subbox .title h4 .tax{
  font-size:14px;
}

#PageComparison #Main #ContBox04 .subbox .sp{
  display:none;
}

#PageComparison #Main #ContBox04 .subbox ul{
  width:220px;
  float:left;
}

#PageComparison #Main #ContBox04 .subbox ul:first-of-type{
  margin-left:25px;
}

#PageComparison #Main #ContBox04 .subbox ul li{
  margin-bottom:6px;
  font-size:16px;
  text-align:left;
  font-weight:bold;
}

#PageComparison #Main #ContBox04 .subbox ul li:before{
     top: -3px;
}

#PageComparison #Main #ContBox04 .maintenance{
  box-sizing:border-box;
  padding:30px 0px 0px 30px;
  height:198px;
  background-color:#EEF5FF;
  border:1px solid #DDE7F3;
}

#PageComparison #Main #ContBox04 .maintenance h3{
  padding-left:30px;
  background:url(../img/contents/top/ContBox04_icon01.png) no-repeat center top;
  font-size:20px;
  color:#0046b1;
  line-height:1;
  font-weight:bold;
}

#PageComparison #Main #ContBox04 .maintenance ul:after{
  content: "";  /* 新しい要素を作る */
  display: block;  /* ブロックレベル要素に */
  clear: both;
}


#PageComparison #Main #ContBox04 .maintenance ul{
  margin-top:25px;
}

#PageComparison #Main #ContBox04 .maintenance ul li{
  float:left;
  text-align:left;
  font-size:14px;
  line-height:1.75;
}

#PageComparison #Main #ContBox04 .maintenance ul li.menu01{
  width:210px;
}

#PageComparison #Main #ContBox04 .maintenance ul li.menu02{
  width:294px;
}

#PageComparison #Main #ContBox04 .maintenance ul li.menu03{
  width:223px;
}

#PageComparison #Main #ContBox04 .maintenance ul li.menu04{
  width:240px;
}

#PageComparison #Main #ContBox04 p.appeal{
  margin-top:20px;
  font-size:14px;
  text-align:center;
  color:#888;
}

}


@media screen and (max-width: 767px) {
/*　#MainTitleWarp
-------------------------------------*/
#PageComparison #MainTitleWarp{
  padding-bottom:14px;
}

#PageComparison #MainTitleWarp h2{
    margin-top:3px;
    padding-top:43px;
    background-size:41px auto;
    line-height:1.2;
}


/*　#ContBox01
-------------------------------------*/
#PageComparison #Main #ContBox01{
    padding-bottom:30px;
}

#PageComparison #Main #ContBox01 h3{
    margin-bottom:15px;
    background:url(../img/contents/comparison/sp_ContBox01_h2_bk.png) no-repeat center bottom;
    background-size:290px auto;
    font-size:24px;
    letter-spacing:-0.08em;
}

#PageComparison #Main #ContBox01 p{
    font-size:15px;
}


/*　#ContBox02
-------------------------------------*/
#PageComparison #Main #ContBox02{
    padding-bottom:30px;
}

#PageComparison #Main #ContBox02 .title p{
    margin-bottom:-5px;
    height:34px;
    background:url(../img/contents/comparison/sp_ContBox01_title01.png) no-repeat center bottom;
    background-size:290px auto;
}

#PageComparison #Main #ContBox02 .title h3{
    margin-bottom:10px;
    font-size:24px;
}

#PageComparison #Main #ContBox02 p.catch{
    font-size:17px;
    line-height:1.4;
}

#PageComparison #Main #ContBox02 .subbox{
    margin-bottom:11px;
}

#PageComparison #Main #ContBox02 .subbox .title{
    padding:20px 0 15px;
}

#PageComparison #Main #ContBox02 .subbox .title h4{
    font-size:20px;
}

#PageComparison #Main #ContBox02 .subbox table{
  margin:2% 6%;
    font-size:15px;
    width:88%;
}

#PageComparison #Main #ContBox02 .subbox table th,
#PageComparison #Main #ContBox02 .subbox table td{
    padding:5px 0;
}

#PageComparison #Main #ContBox02 .subbox table td{
    width:80px;
}

#PageComparison #Main #ContBox02 .subbox table td span.font03{
    font-size:18px;
}

#PageComparison #Main #ContBox02 .subbox table td span.price{
    font-size:9px;
}

#PageComparison #Main #ContBox02 .subbox p.appeal{
    font-size:17px;
    text-align:center;
    letter-spacing:-0.05em;
}

/*　#ContBox03
-------------------------------------*/
#PageComparison #Main #ContBox03{
    padding-bottom:30px;
}

#PageComparison #Main #ContBox03 .title p{
    margin-bottom:-5px;
    height:34px;
    background:url(../img/contents/comparison/sp_ContBox01_title03.png) no-repeat center bottom;
    background-size:290px auto;
    text-indent:-9999px;
}

#PageComparison #Main #ContBox03 .title h3{
    margin-bottom:10px;
    font-size:24px;
    font-weight:bold;
    text-align:center;
}

#PageComparison #Main #ContBox03 p.catch{
    font-size:17px;
    line-height:1.6;
    text-align:center;
}

#PageComparison #Main #ContBox03 .box01_Warp{
  background:url(../img/contents/sp_hikaku_img01_bk.png) repeat-x left top;
  background-size:1px auto;
}

#PageComparison #Main #ContBox03 .box01{
    position:relative;
    margin-bottom:5px;
    height:413px;
    background:url(../img/contents/sp_hikaku_img01.png) no-repeat center top;
    background-size:290px auto;
}

#PageComparison #Main #ContBox03 .box01 p{
    position:absolute;
    top:50px;
    left:135px;
}

#PageComparison #Main #ContBox03 h3.title02{
    margin-bottom:15px;
}

#PageComparison #Main #ContBox03 .box02{
    margin:5px auto;
}

/*　#ContBox04
-------------------------------------*/
#PageComparison #Main #ContBox04 p.top{
    margin:0 auto 5px;
    width:290px;
    height:34px;
    background:url(../img/contents/top/sp_ContBox04_ribbon.png) no-repeat left top;
    background-size:290px auto;
    text-indent:-9999px;
}

#PageComparison #Main #ContBox04 h3{
    margin-bottom:20px;
    font-size:24px;
    font-weight:bold;
    line-height:1;
}

#PageComparison #Main #ContBox04 .subbox{
    margin-bottom:11px;
    border-radius:6px;
    -moz-border-radius:6px;
    -webkit-border-radius:6px;
}

#PageComparison #Main #ContBox04 .box01{
    border:4px solid #11B3C0;
}

#PageComparison #Main #ContBox04 .box02{
    border:4px solid #BCA734;
}

#PageComparison #Main #ContBox04 .box01 .title{
  background-color:#11B3C0;
}

#PageComparison #Main #ContBox04 .box02 .title{
  background-color:#BCA734;
}

#PageComparison #Main #ContBox04 .subbox .title{
    padding:10px 0;
    color:#fff;
}

#PageComparison #Main #ContBox04 .subbox .title p{
    text-align:center;
    font-size:13px;
}

#PageComparison #Main #ContBox04 .subbox .title h4{
    font-size:20px;
}

#PageComparison #Main #ContBox04 .subbox .title h4 .font03{
    font-size:34px;
}

#PageComparison #Main #ContBox04 .subbox .title h4 .tax{
    font-size:10px;
}

#PageComparison #Main #ContBox04 .subbox .pc{
    display:none;
}

#PageComparison #Main #ContBox04 .subbox ul{
    padding:15px 0 15px 30px;
}

#PageComparison #Main #ContBox04 .subbox ul.first{
    padding:15px 0 0 30px;
}

#PageComparison #Main #ContBox04 .subbox ul.second{
    padding:0 0 15px 30px;
}


#PageComparison #Main #ContBox04 .subbox ul li{
    text-align:left;
    font-weight:bold;
    line-height:2;
}

#PageComparison #Main #ContBox04 .subbox ul li:before{
    top: -5px;
}

#PageComparison #Main #ContBox04 .maintenance{
    padding:10px 10px 20px 10px;
    background-color:#EEF5FF;
    border:1px solid #DDE7F3;
}

#PageComparison #Main #ContBox04 .maintenance h3{
    padding-top:20px;
    background:url(../img/contents/top/sp_ContBox04_icon01.png) no-repeat center top;
    font-size:20px;
    color:#0046b1;
    line-height:1.2;
    text-align:center;
}

#PageComparison #Main #ContBox04 .maintenance ul{
    margin-top:-10px;
}

#PageComparison #Main #ContBox04 .maintenance ul li{
    text-align:left;
    font-size:15px;
    line-height:1.6;
}

#PageComparison #Main #ContBox04 p.appeal{
  margin-top:10px;
  font-size:14px;
  text-align:left;
  color:#888;
}

}


/*####################　PageShop　####################*/
/*　#MainTitleWarp
-------------------------------------*/
#PageShop #MainTitleWarp h2{
  background-image:url(../img/contents/shop/main_bk.png);
}

/*　#ContBox01
-------------------------------------*/
#PageShop #Main #ContBox01 table.company{
  border-top:1px solid #E7E7E7;
  border-bottom:1px solid #E7E7E7;
}

#PageShop #Main #ContBox01 table.company tr{
  border-top:1px solid #E7E7E7;
}

#PageShop #Main #ContBox01 table.company th,
#PageShop #Main #ContBox01 table.company td{
  line-height:1;
}

#PageShop #Main #ContBox01 table.company th{
  color:#888;
}

/*　#ContBox02
-------------------------------------*/
#PageShop #Main #ContBox02 .txt h3{
  background:url(../img/contents/shop/logo.png) no-repeat left top;
  text-indent:-9999px;
}

#PageShop #Main #ContBox02 .txt p{
  font-weight:bold;
}

#PageShop #Main #ContBox02 .access{
  background-color:#EEF5FF;
  border:1px solid #DDE7F3;
}

#PageShop #Main #ContBox02 .access h4{
  background:url(../img/contents/shop/icon.png) no-repeat left top;
  background-size:15px auto;
  font-size:20px;
  color:#0046b1;
  line-height:1.2;
}

#PageShop #Main #ContBox02 .access ul li{
    padding-left:12px;
    background:url(../img/contents/shop/icon_list.png) no-repeat left 10px;
    background-size:3px auto;
    text-align:left;
    line-height:1.7;
}

@media print, screen and (min-width: 768px) {
/*　#MainTitleWarp
-------------------------------------*/
#PageShop #MainTitleWarp h2{
    background-size:36px auto;
}

/*　#ContBox01
-------------------------------------*/
#PageShop #Main #ContBox01{
  padding-top:30px;
}

#PageShop #Main #ContBox01 table.company{
  margin-top:40px;
  width:500px;
  float:left;
}

#PageShop #Main #ContBox01 table.company th,
#PageShop #Main #ContBox01 table.company td{
  box-sizing:border-box;
  padding:22px 0;
  font-size:16px;
  height:60px;
}

#PageShop #Main #ContBox01 table.company th{
  width:102px;
}

#PageShop #Main #ContBox01 table.company td p img{
  display:none;
}

#PageShop #Main #ContBox01 table.company td p{
  box-sizing:border-box;
  position:relative;
  top:-10px;
  right:55px;
  margin-bottom:-30px;
  font-size:12px;
  text-indent:-6px;
  width:204px;
  height:35px;
  border-radius:53px;
  -moz-border-radius:53px;
  -webkit-border-radius:53px;
  border:2px solid #D9D9D9;
  float:right;
  line-height:1;
}

#PageShop #Main #ContBox01 table.company td p a{
  box-sizing:border-box;
  padding-top:10px;
  display:block;
  width:100%;
  height:100%;
  color:#002f76;
  text-align:center;
  background:url(../img/contents/shop/icon_link.png) no-repeat right center;
}

#PageShop #Main #ContBox01 table.company td a.link:hover{
  opacity:0.7;
}

#PageShop #Main #ContBox01 table.company td a.tel{
  cursor:default;
}

#PageShop #Main #ContBox01 .info_warp:after{
  content: "";  /* 新しい要素を作る */
  display: block;  /* ブロックレベル要素に */
  clear: both;
}

#PageShop #Main #ContBox01 .info_warp{
  margin-top:30px;
}

#PageShop #Main #ContBox01 table.txt{
  width:470px;
  height:169px;
  float:left;
}

#PageShop #Main #ContBox01 table.txt td{
  padding-top:15px;
}

#PageShop #Main #ContBox01 table.txt td.txt_img{
  width:119px;
}

#PageShop #Main #ContBox01 table.txt td.txt_message{
  padding-left:20px;
  width:351px;
  vertical-align:top;
  font-size:14px;
}

#PageShop #Main #ContBox01 .info_warp img.shop_img{
  width:511px;
  height:169px;
  float:right;
}


/*　#ContBox02
-------------------------------------*/
#PageShop #Main #ContBox02{
  margin-top:80px;
  padding-top:80px;
}

#PageShop #Main #ContBox02 .map_warp:after{
  content: "";  /* 新しい要素を作る */
  display: block;  /* ブロックレベル要素に */
  clear: both;
}

#PageShop #Main #ContBox02 .map_warp img.map{
  float:left;
  width:587px;
  height:360px;
}


#PageShop #Main #ContBox02 .txt{
  float:right;
  width:385px;
}

#PageShop #Main #ContBox02 .txt h3{
  width:234px;
  height:53px;
  background-size:234px auto;
}

#PageShop #Main #ContBox02 .txt p{
  margin:20px 0 50px;
  line-height:1.2;
  font-size:26px;
}

#PageShop #Main #ContBox02 .txt img{
  width:385px;
  height:143px;
}

#PageShop #Main #ContBox02 .access:after{
  content: "";  /* 新しい要素を作る */
  display: block;  /* ブロックレベル要素に */
  clear: both;
}

#PageShop #Main #ContBox02 .access{
  margin-top:25px;
  padding:20px;
}

#PageShop #Main #ContBox02 .access h4{
  margin:3px 65px 0 0;
  padding-left:12px;
  width:102px;
  height:24px;
  float:left;
  font-weight:bold;
}

#PageShop #Main #ContBox02 .access ul{
  float:left;
}

#PageShop #Main #ContBox02 .access ul:first-of-type{
  width:250px;
}

#PageShop #Main #ContBox02 .access ul:nth-of-type(2){
  width:360px;
}

#PageShop #Main #ContBox02 .access ul li{
    font-size:14px;
}

}


@media screen and (max-width: 767px) {
/*　#MainTitleWarp
-------------------------------------*/
#PageShop #MainTitleWarp h2{
    background-size:27px auto;
}

/*　#ContBox01
-------------------------------------*/
#PageShop #Main #ContBox01{
    padding-bottom:30px;
}

#PageShop #Main #ContBox01 table.company{
    width:100%;
}

#PageShop #Main #ContBox01 table.company:first-of-type{
    margin-top:25px;
    border-bottom:none;
}

#PageShop #Main #ContBox01 table.company th,
#PageShop #Main #ContBox01 table.company td{
    padding:18px 0;
    font-size:18px;
}

#PageShop #Main #ContBox01 table.company th{
    width:109px;
}

#PageShop #Main #ContBox01 table.company td{
  line-height:1.5;
}

#PageShop #Main #ContBox01 table.company td a.link,
#PageShop #Main #ContBox01 table.company td a.tel{
    text-decoration:underline;
}


#PageShop #Main #ContBox01 table.company td a.link{
    font-size:12px;
    color:#002f76;
}

#PageShop #Main #ContBox01 table.company td p img{
    position:relative;
    top:-1px;
    left:2px;
    width:12px;
    height:auto;
}

#PageShop #Main #ContBox01 table.company td p{
    margin-bottom:-5px;
}

#PageShop #Main #ContBox01 .info_warp{
    margin-top:25px;
}

#PageShop #Main #ContBox01 table.txt{
    margin-bottom:15px;
}

#PageShop #Main #ContBox01 table.txt td{
    vertical-align:middle;
}

#PageShop #Main #ContBox01 table.txt td.txt_img{
    width:119px;
}

#PageShop #Main #ContBox01 table.txt td.txt_img img{
    width:119px;
    height:119px;
}

#PageShop #Main #ContBox01 table.txt td.txt_message{
    padding-left:20px;
    font-size:14px;
}

/*　#ContBox02
-------------------------------------*/
#PageShop #Main #ContBox02 p.sp_display{
    text-align:center;
    font-size:15px;
}

#PageShop #Main #ContBox02 .txt{
    margin-top:15px;
}

#PageShop #Main #ContBox02 .txt h3{
    height:42px;
    background-size:178px auto;
}

#PageShop #Main #ContBox02 .txt p{
    margin-top:5px;
    font-size:19px;
}

#PageShop #Main #ContBox02 .access{
    margin-top:20px;
    padding:20px;
}

#PageShop #Main #ContBox02 .access h4{
    margin-bottom:15px;
    padding-left:20px;
}

#PageShop #Main #ContBox02 .access ul li{
    font-size:15px;
}


}

/*####################　PageFaq　####################*/
/*　#MainTitleWarp
-------------------------------------*/
#PageFaq #MainTitleWarp h2{
  background-image:url(../img/contents/faq/main_bk.png);
}

/*　#ContBox
-------------------------------------*/
#PageFaq .contBox .txt h3{
  font-weight:bold;
}

#PageFaq .contBox .txt h3 span{
  color:#da2634;
}

@media print, screen and (min-width: 768px) {
/*　#MainTitleWarp
-------------------------------------*/
#PageFaq #MainTitleWarp h2{
  background-size:61px auto;
}

/*　#ContBox
-------------------------------------*/
#PageFaq .contBox:after{
  content: "";  /* 新しい要素を作る */
  display: block;  /* ブロックレベル要素に */
  clear: both;
}

#PageFaq .contBox .txt{
  text-align:left;
}

#PageFaq .contBox .txt h3{
  margin-top:-10px;
  margin-bottom:30px;
  font-size:24px;
  line-height:1.1;
  letter-spacing:-0.03em;
}

#PageFaq .contBox .txt h3 span{
    font-size:50px;
    background:url(../img/contents/faq/icon_faq.png) no-repeat left 32px;
    background-size:30px auto;
}

#PageFaq .contBox .txt h3 span.title01{
  padding:2px 0 0 23px;
}

#PageFaq .contBox .txt h3 span.title02,
#PageFaq .contBox .txt h3 span.title03,
#PageFaq .contBox .txt h3 span.title04,
#PageFaq .contBox .txt h3 span.title05{
  padding:2px 0 0 30px;
}

#PageFaq .contBox .txt p{
  margin-bottom:25px;
  font-size:16px;
  line-height:28px;
}

#PageFaq .contBox{
  margin-bottom:75px;
}

#PageFaq .contBox:first-of-type{
  margin-top:77px;
}

#PageFaq .contBox.box01 .txt{
  width:420px;
  float:right;
}

#PageFaq .contBox.box01 .movebox{
  width:540px;
  float:left;
}

#PageFaq .contBox.box02 .txt{
  width:428px;
  float:left;
}

#PageFaq .contBox.box02 .movebox{
  width:540px;
  float:right;
}

#PageFaq .contBox.box_title01{
  margin:77px 0 49px;
}

#PageFaq .contBox.box_title02{
  margin-bottom:75px;
}

#PageFaq .contBox.box_title03{
  margin-bottom:75px;
}

#PageFaq .contBox.box_title04{
  margin-bottom:75px;
}

#PageFaq .contBox.box_title04 img{
  margin-right:15px;
  width:180px;
  height:180px;
}

}
  
  
  
@media screen and (max-width: 767px) {
/*　#MainTitleWarp
-------------------------------------*/
#PageFaq #MainTitleWarp h2{
    background-size:44px auto;
}

/*　#ContBox
-------------------------------------*/
#PageFaq .contBox .txt h3,
#PageFaq .contBox .txt p{
    padding:0 15px;
}

#PageFaq .contBox .txt h3{
    margin:15px 0;
    font-size:20px;
    line-height:1.2;
}

#PageFaq .contBox .txt h3 span{
    padding:2px 0 0 24px;
    font-size:37px;
    background:url(../img/contents/faq/icon_faq.png) no-repeat left 22px;
    background-size:22px auto;
}

#PageFaq .contBox{
    margin-top:40px;
}

#PageFaq .contBox .movebox {
    width: auto;
    height: auto;
    background: none;
    margin: 0 15px;
    position: relative;
    padding-top: 56.25%;
}

#PageFaq .contBox .movebox iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#PageFaq .contBox .innerBasic{
    padding: 0;
  }

#PageFaq .contBox.box_title04 img{
    margin:0 10px 36px 0;
    width:135px;
    height:135px;
}

}


/*####################　PageContact　####################*/
/*　#MainTitleWarp
-------------------------------------*/
#PageContact #MainTitleWarp h2{
  background-image:url(../img/contents/contact/main_bk.png);
}
#PageContact #Main .formBox td.list01 ul li,
#PageContact #Main .formBox td.list02 ul li,
#PageContact #Main .formBox dl dt,
#PageContact #Main .formBox dl dd{
  display:inline-block;
}

#PageContact #Main .formBox dl{
  margin-bottom:10px;
}

#PageContact #Main .formBox table td p.apple{
    font-size:14px;
    color:#888;
}

#PageContact #Main .formBox td.list01 ul li,
#PageContact #Main .formBox td.list02 ul li{
  margin-right:10px;
}

/* input[type="text"],textarea */
.form #Main .formBox input[type="text"],
.form #Main .formBox textarea,
.form #Main .formBox .radio span,
.form #Main .formBox .checker span,
.form #Main .formBox .selector{
  border-radius: 4px;
  color: #555;
  resize: none;
}

#PageContact #Main .formBox input[type="text"],
#PageContact #Main .formBox textarea{
  border: 1px solid #ccc;
  background-color: #f5f5f5;
}
#PageContact #Main .formBox input[type="text"].calendar{
  background-repeat: no-repeat;
  background-position: right center;
  background-image: url("../img/contents/contact/calendar.jpg");
  background-size: 45px auto;
  
}

#PageContact #Main .formBox input[type="text"]:focus,
#PageContact #Main .formBox textarea:focus{
  background-color:#fff;
  border: 1px solid #002F75;
}

/* .radio */
.form #Main .formBox table td .radio,
.form #Main .formBox table td .checker{
    resize: none;
    box-sizing: border-box;
    border-radius: 0;
  position:relative;
  width:30px;
  height:30px;
    border: 1px solid #d3d3d3;
    background-color: #F8F8F8;
}
.form #Main .formBox table td .radio{
  margin-right:8px;
  top:-2px;
  border-radius: 50%;
}
.form #Main .formBox table td .checker{
}
.form #Main .formBox table td .radio span.checked,
.form #Main .formBox table td .checker span.checked{
  background-repeat: no-repeat;
  background-position: center center;
}
.form #Main .formBox table td .radio span.checked {
    background-image: url(../img/contents/contact/radio_01.png);
    background-size: 12px auto;
}
.form #Main .formBox table td .checker span.checked {
    background-image: url("../img/contents/contact/checker_01.png");
    background-size: 22px auto;
}
.form #Main .formBox table td .radio span input,
.form #Main .formBox table td .checker span input{
    width: 28px;
    height: 28px;
}
.form #Main .formBox table td .radio span,
.form #Main .formBox table td .checker span{
    padding: 0;
    width: 28px;
    height: 28px;
}

/*　確認画面　*/
#Main .kakuninBox p.top{
  text-align:center;
}

#Main .kakuninBox ul.button li.back{
  margin:15px auto 0;
  margin-bottom:15px;
  font-size:14px;
  text-align:center;
}

#Main .kakuninBox ul.button li.back input{
  padding-left:13px;
  text-decoration:underline;
  background:url(../img/contents/contact/icon_back.png) no-repeat left center;
  background-size:7px auto;
  color:#888;
}

/* ボタン */
.form #Main .formBox ul.button{
  margin-top:30px;
  text-align: center;
}

.form #Main .formBox ul.button li.submit input[type="submit"],
#Main #ContBox01 .errorBox ul.button li.return,
.thanks #Main #ContBox01 p.top{
  margin:0 auto;
  display: block;
  border-radius: 5px;
  text-decoration: none;
}

.form #Main .formBox ul.button li.submit input[type="submit"],
#Main #ContBox01 .errorBox ul.button li.return input,
.thanks #Main #ContBox01 p.top a{
  color:#fff;
  border-radius:53px;
  -moz-border-radius:53px;
  -webkit-border-radius:53px;
}

.form #Main .formBox ul.button li.submit input[type="submit"]{
  background:#DA2534 url(../img/contents/contact/icon_btn.png) no-repeat right center;
  background-size:42px auto;
}

.thanks #Main #ContBox01 p.top a,
#Main #ContBox01 .errorBox ul.button li.return input{
  background:#D1D1D1 url(../img/contents/contact/icon_btn.png) no-repeat right center;
  background-size:42px auto;
  display:block;
  width:100%;
  height:100%;
}

/*　エラー画面　*/
#Main #ContBox01 .messageBox{
  font-size:18px;
  text-align:center;
}

#Main #ContBox01 .messageBox ul{
  margin-top:15px;
}

#Main #ContBox01 .messageBox ul li:before{
  font-size:11px;
  content: "・";
    margin-right: 5px;
  position: relative;
     top: 0;
}

/*　完了画面　*/
#PageContact.thanks #Main #ContBox01{
  padding-top: 50px !important;
}
.thanks #Main #ContBox01 p{
    margin-bottom:30px;
    font-size:18px;
    line-height:1.5;
    text-align:center;
}

.thanks #Main #ContBox01 p.top a{
    margin:0 auto;
    box-sizing:border-box;
    padding-top:13px;
    background-size:42px auto;
    text-decoration:none;
}

/*　#ContBox01
-------------------------------------*/
#PageContact #Main #ContBox01{
  margin-top: 0;
  padding-top: 0 !important;
}
#PageContact.kakunin #Main #ContBox01.formBox{
  padding-top: 80px !important;
}

#PageContact #Main #ContBox01 h3{
  background-color: #033c95;
}
#PageContact #Main #ContBox01 h3 span{
  display: block;
  margin: 0 auto;
  background-color: #fff;
  text-align: center;
  font-weight: bold;
  color: #0046b1;
}
#PageContact #Main #ContBox01 h3 span b{
  background-color: #da2634;
  line-height: 1;
  text-align: center;
  color: #fff;
}

/*　#ContBox02
-------------------------------------*/
#PageContact #Main #ContBox02 h3{
  font-weight:bold;
  text-align:center;
}

#PageContact #Main #ContBox02 p.top{
  text-align:center;
}

#PageContact #Main #ContBox02 .subbox{
  /*padding-bottom:5px;*/
  border:1px solid #cacaca;
  border-radius:6px;
  -moz-border-radius:6px;
  -webkit-border-radius:6px;
}

#PageContact #Main #ContBox02 .subbox .title{
  box-sizing:border-box;
  border-bottom:1px solid #cacaca;
}

#PageContact #Main #ContBox02 .subbox .title h4{
  font-size:17px;
  text-align:center;
  font-weight:bold;
}

#PageContact #Main #ContBox02 .subbox p,
#PageContact #Main #ContBox02 .subbox ul li,
#PageContact #Main #ContBox02 .subbox a{
  color:#888;
}

#PageContact #Main #ContBox02 .subbox ul li{
  text-align:left;
}

#PageContact #Main #ContBox02 .subbox ul li:before{
  font-size:11px;
  content: "・";
  margin-right: 5px;
  position: relative;
  top: 0;
}

#PageContact #Main #ContBox02 .subbox ul:last-of-type{
  padding-bottom:15px;
}

#PageContact #Main #ContBox02 .subbox a{
  text-decoration:underline;
}

/*　#ContBox03
-------------------------------------*/
#PageContact #Main #ContBox03 h3{
  font-weight:bold;
  text-align:center;
}


@media print, screen and (min-width: 768px) {
  /*　#MainTitleWarp
  -------------------------------------*/
  #PageContact #MainTitleWarp h2{
    background-image:url(../img/contents/contact/main_bk.png);
    background-size:44px auto;
  }
  #PageContact #Main .formBox{
    padding-top:80px;
  }

  #PageContact #Main .formBox table{
    border-top:1px solid #E7E7E7;
  }

  #PageContact #Main .formBox table tr{
    border-bottom:1px solid #E7E7E7;
  }

  #PageContact #Main .formBox table th{
    box-sizing:border-box;
    width:250px;
    /*padding:15px 0 10px;*/
  }

  #PageContact #Main .formBox table th{
    padding-top:19px;
    vertical-align:top;
    font-weight:bold;
    font-size:16px;
    line-height:1.6;
    text-indent: -60px;
    padding: 20px 15px 20px 60px;
  }

  #PageContact #Main .formBox table th.hissu span:before,
  #PageContact.contact #Main table tr:nth-of-type(2) th span:before,
  #PageContact.contact #Main table tr:nth-of-type(4) th span:before,
  #PageContact.contact #Main table tr:nth-of-type(5) th span:before,
  #PageContact.contact #Main table tr:nth-of-type(6) th span:before,
  #PageContact.reserve #Main table tr:nth-of-type(1) th span:before,
  #PageContact.reserve #Main table tr:nth-of-type(2) th span:before,
  #PageContact.reserve #Main table tr:nth-of-type(3) th span:before,
  #PageContact.reserve #Main table tr:nth-of-type(4) th span:before{
    box-sizing:border-box;
    padding:5px 4px 6px 6px;
    position:relative;
    top:-1px;
    margin-right:18px;
    content: "必須";
    font-size: 12px;
    background: #da2634;
    color: #fff;
    text-align:center;
    font-weight:100;
    line-height:1;
    letter-spacing:0.3em;
  }

  #PageContact #Main .formBox table th.nini span:before,
  #PageContact.contact #Main table tr:nth-of-type(1) th span:before,
  #PageContact.contact #Main table tr:nth-of-type(3) th span:before,
  #PageContact.contact #Main table tr:nth-of-type(7) th span:before,
  #PageContact.reserve #Main table tr:nth-of-type(5) th span:before,
  #PageContact.reserve #Main table tr:nth-of-type(6) th span:before,
  #PageContact.reserve #Main table tr:nth-of-type(7) th span:before,
  #PageContact.reserve #Main table tr:nth-of-type(8) th span:before,
  #PageContact.reserve #Main table tr:nth-of-type(9) th span:before{
    box-sizing:border-box;
    padding:5px 4px 6px 6px;
    position:relative;
    top:-1px;
    margin-right:18px;
    content: "任意";
    font-size: 12px;
    background: #ECF3FD;
    color: #002f76;
    border:1px solid #DFE5F1;
    text-align:center;
    font-weight:100;
    line-height:1;
    letter-spacing:0.3em;
  }

  #PageContact #Main .formBox table td{
    width:750px;
    padding:10px 0 ;
    font-size:16px;
    vertical-align:middle;
  }

  #PageContact .formBox table td p{
      margin-top:6px;
  }

  #PageContact #Main .formBox td.list03 ul li{
    display:inline-block;
  }

  #PageContact #Main .formBox td.list03 ul{
    padding: 6px 0 4px;
  }
  #PageContact #Main .formBox td.list01 ul li{  width:140px;}
  #PageContact #Main .formBox td.list02 ul li{  width:110px;}
  #PageContact #Main .formBox td.list03 ul li{
    margin-bottom: 9px;
    margin-right: 26px;
  }
  #PageContact #Main .formBox td.list03 ul li label{
    margin-left: 15px;
  }

  #PageContact #Main .formBox td.kind ul li{
    display:inline-block;
  }

  #PageContact #Main .formBox td.kind ul li{
    margin-right:40px;
    width:320px;
  }

  #PageContact #Main .formBox td.kind dl dt.title01{
    width:70px;
  }

  #PageContact #Main .formBox td.kind dl dt.title02{
    width:40px;
  }

  #PageContact #Main .formBox td.kind dl dd{
    width:240px
  }

  /* input[type="text"],textarea */
    .form #Main .formBox input[type="text"],
    .form #Main .formBox textarea{
      padding: 9px 14px;
      width: 260px;
      font-size: 15px;
    }

    .form #Main .formBox input[type="text"]#zip{
      width: 180px;
    }

    .form #Main .formBox input[type="text"]#address{
      width: 730px;
    }

    .form #Main .formBox textarea{
      width: 730px;
      height: 130px;
    }

    .form #Main .formBox input[type="text"]#mailaddress,
    .form #Main .formBox input[type="text"]#mailaddress2{
      width: 480px;
    }

    .form #Main .formBox dl dd input[type="text"]#tel{
      width: 210px;
    }

    .form #Main .formBox dl dd input[type="text"]#car_maker,
    .form #Main .formBox dl dd input[type="text"]#car_type,
    .form #Main .formBox dl dd input[type="text"]#car_grade,
    .form #Main .formBox dl dd input[type="text"]#car_color{
      width: 240px;
    }


  /* ボタン */
  .form #Main .formBox ul.button li.submit input[type="submit"]{
    background-size:42px auto;
    width:395px;
    height:55px;
  }

  .form #Main .formBox ul.button li.submit input[type="submit"],
  #Main #ContBox01 .errorBox ul.button li.return input{
    font-size:18px;
    font-weight:bold;
  }

  .form #Main .formBox ul.button li.submit input[type="submit"]:hover,
  #Main #ContBox01 .errorBox ul.button li.return input:hover{
    opacity:0.7;
  }

  #PageContact #Main .formBox input[type="text"]::-webkit-input-placeholder{
    font-size: 16px;
    color: #bcbcbc;
  }

  #PageContact #Main .formBox input[type="text"]:-ms-input-placeholder{
    font-size: 16px;
    color: #bcbcbc;
  }

  #PageContact #Main .formBox input[type="text"]::-moz-placeholder{
    font-size: 16px;
    color: #bcbcbc;
  }

  #PageContact #Main .formBox textarea::-webkit-input-placeholder{
    font-size: 16px;
    color: #bcbcbc;
  }

  #PageContact #Main .formBox textarea:-ms-input-placeholder{
    font-size: 16px;
    color: #bcbcbc;
  }

  #PageContact #Main .formBox textarea::-moz-placeholder{
    font-size: 16px;
    color: #bcbcbc;
  }

  /*　エラー画面　*/
  #Main #ContBox01 .errorBox{
    padding-top: 80px;
  }
  #Main #ContBox01 .errorBox ul.button li.return input{
    box-sizing:border-box;
    /*padding-top:12px;*/
    margin:0 auto;
    background-size:42px auto;
    width:350px;
    height:55px;
  }

  /*　確認画面　*/
  #Main .kakuninBox p.top{
    margin-bottom:60px;
    font-size:18px;
  }

  #Main .kakuninBox ul.button{
    position:relative;
  }

  #Main .kakuninBox ul.button li.back{
    position:absolute;
    top:5px;
  }

  #Main .kakuninBox ul.button li.back input{
    padding-left:13px;
    background:url(../img/contents/contact/icon_back.png) no-repeat left center;
    background-size:7px auto;
    color:#888;
  }

  /*　完了画面　*/
  .thanks #Main #ContBox01 p.top a{
    width:391px;
    height:55px;
    font-weight:bold;
  }

  .thanks #Main #ContBox01 p.top a:hover{
    opacity:0.7;
  }


  /*　#ContBox01
  -------------------------------------*/
  #PageContact #Main #ContBox01 h3{
    margin-bottom: 60px;
    padding-top: 35px;
    padding-bottom: 35px;
  }
  #PageContact #Main #ContBox01 h3 span{
    border-radius: 18px;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 1000px;
    font-size: 42px;
  }
  #PageContact #Main #ContBox01 h3 span br{
    display: none;
  }
  #PageContact #Main #ContBox01 h3 span b{
    display: inline-block;
    margin-left: 15px;
    border-radius: 8px;
    padding: 9px 17px;
    font-size: 32px;
  }
  /*　#ContBox02
  -------------------------------------*/
  #PageContact #Main #ContBox02{
    padding-bottom:80px;
  }

  #PageContact #Main #ContBox02 h3{
    margin-bottom:20px;
    font-size:40px;
  }

  #PageContact #Main #ContBox02 p.top{
    font-size:18px;
  }

  #PageContact #Main #ContBox02 p.top:last-of-type{
    margin-bottom:35px;
  }

  #PageContact #Main #ContBox02 .subbox p,
  #PageContact #Main #ContBox02 .subbox ul li,
  #PageContact #Main #ContBox02 .subbox a{
    font-size:14px;
  }

  #PageContact #Main #ContBox02 .subbox p,
  #PageContact #Main #ContBox02 .subbox ul{
    padding:0 30px;
  }

  #PageContact #Main #ContBox02 .subbox p:first-of-type{
    padding-top:30px;
  }

  #PageContact #Main #ContBox02 .box01,
  #PageContact #Main #ContBox02 .box02{
    width:491px;
  }

  #PageContact #Main #ContBox02 .box01{
    float:left;
  }

  #PageContact #Main #ContBox02 .box02{
    float:right;
  }

  #PageContact #Main #ContBox02 .subbox{
    padding-bottom:10px;
    margin-bottom:20px;
  }

  #PageContact #Main #ContBox02 .subbox .title{
    height:60px;
    padding:18px 0;
  }
  /*　#ContBox03
  -------------------------------------*/
  #PageContact #Main #ContBox03{
    margin-top: 0;
    padding-top: 86px;
    padding-bottom: 28px;
  }

  #PageContact #Main #ContBox03 h3{
    margin-bottom: 46px;
    font-size: 50px;
  }
  #PageContact #ContBox03 td.list03 ul li label{
    margin-left: 8px !important;
  }
  #PageContact #ContBox03 td.list03 ul{
    margin-bottom: 5px;
  }
  #PageContact #ContBox03 td.list03 ul + input{
    width: 450px;
  }
  #PageContact #ContBox03 td input.calendar{
    width: 290px;
  }
}


@media screen and (max-width: 767px) {
  /*　#MainTitleWarp
  -------------------------------------*/
  #PageContact #MainTitleWarp{
      padding-bottom:17px;
  }

  #PageContact #MainTitleWarp h2{
      margin-top:15px;
      padding-top:28px;
      background-image:url(../img/contents/contact/main_bk.png);
      background-size:33px auto;
      line-height:1.2;
  }

  #PageContact .formBox{
      padding-bottom:30px;
  }

  #PageContact .formBox table{
      width:100%;
      border-top:1px solid #E7E7E7;
  }

  #PageContact .formBox table tr{
      border-bottom:1px solid #E7E7E7;
  }

  #PageContact .formBox table td,
  #PageContact .formBox table th{
      width:100%;
      display:block;
  }

  #PageContact .formBox table th{
      padding:15px 0 10px;
  }

  #PageContact .formBox table td{
      padding-bottom:15px;
  }

  #PageContact .formBox table th{
      /*padding-left:45px;*/
      padding-bottom:18px;
      position:relative;
      font-weight:bold;
      background:no-repeat left 18px;
      background-size:40px auto;
  }

  #PageContact #Main .formBox table th.hissu span:before,
  #PageContact.contact #Main table tr:nth-of-type(2) th span:before,
  #PageContact.contact #Main table tr:nth-of-type(4) th span:before,
  #PageContact.contact #Main table tr:nth-of-type(5) th span:before,
  #PageContact.contact #Main table tr:nth-of-type(6) th span:before,
  #PageContact.reserve #Main table tr:nth-of-type(1) th span:before,
  #PageContact.reserve #Main table tr:nth-of-type(2) th span:before,
  #PageContact.reserve #Main table tr:nth-of-type(3) th span:before,
  #PageContact.reserve #Main table tr:nth-of-type(4) th span:before{
    box-sizing:border-box;
    padding:1px 4px 1px 6px;
    position:relative;
    top:-1px;
    margin-right:13px;
    content: "必須";
    font-size: 12px;
    background: #da2634;
    color: #fff;
    text-align:center;
    font-weight:100;
    line-height:1;
    letter-spacing:0.3em;
  }

  #PageContact #Main .formBox table th.nini  span:before,
  #PageContact.contact #Main table tr:nth-of-type(1) th span:before,
  #PageContact.contact #Main table tr:nth-of-type(3) th span:before,
  #PageContact.contact #Main table tr:nth-of-type(7) th span:before,
  #PageContact.reserve #Main table tr:nth-of-type(5) th span:before,
  #PageContact.reserve #Main table tr:nth-of-type(6) th span:before,
  #PageContact.reserve #Main table tr:nth-of-type(7) th span:before,
  #PageContact.reserve #Main table tr:nth-of-type(8) th span:before,
  #PageContact.reserve #Main table tr:nth-of-type(9) th span:before{
    box-sizing:border-box;
    padding:1px 4px 1px 6px;
    position:relative;
    top:-1px;
    margin-right:13px;
    content: "任意";
    font-size: 12px;
    background: #ECF3FD;
    color: #002f76;
    border:1px solid #DFE5F1;
    text-align:center;
    font-weight:100;
    line-height:1;
    letter-spacing:0.3em;
  }

  #PageContact .formBox table td p:first-of-type{
      font-size:18px;
  }

  #PageContact .formBox table td p{
      margin-top:8px;
  }

  #PageContact .formBox dl dt,
  #PageContact .formBox dl dd{
    display:inline-block;
  }

  #PageContact .formBox dl dt{
      width:25%;
  }

  #PageContact .formBox dl dd{
      width:70%;
  }
  #PageContact #Main .formBox td.list03 ul{
    margin-top: -8px;
    margin-bottom: -13px;
  }
  #PageContact #Main .formBox td.list03 ul + input{
    margin-top: 10px;
  }
  #PageContact #Main .formBox td.list03 ul li{
    margin-bottom: 14px;
  }
  #PageContact #Main .formBox td.list03 ul li label{
    margin-left: 10px;
  }

  /* input[type="text"],textarea */
  #PageContact .formBox table td input[type="text"],
  #PageContact .formBox table td textarea{
      padding: 9px 8px;
      width: 95%;
      font-size: 16px;
    }

  #PageContact .formBox table td textarea{
      height: 200px;
    }

  /* ボタン */
  .form .formBox ul.button li.submit input[type="submit"]{
      background-size:42px auto;
      width:290px;
      height:55px;
      font-size:18px;
      font-weight:bold;
  }

  #PageContact #Main .formBox input[type="text"]::-webkit-input-placeholder{
    font-size: 18px;
    color: #bcbcbc;
  }

  #PageContact #Main .formBox input[type="text"]:-ms-input-placeholder{
    font-size: 18px;
    color: #bcbcbc;
  }

  #PageContact #Main .formBox input[type="text"]::-moz-placeholder{
    font-size: 18px;
    color: #bcbcbc;
  }

  #PageContact #Main .formBox textarea::-webkit-input-placeholder{
    font-size: 18px;
    color: #bcbcbc;
  }

  #PageContact #Main .formBox textarea:-ms-input-placeholder{
    font-size: 18px;
    color: #bcbcbc;
  }

  #PageContact #Main .formBox textarea::-moz-placeholder{
    font-size: 18px;
    color: #bcbcbc;
  }


  /* エラー画面 */
  #Main #ContBox01 .errorBox{
    padding-top: 18px;
  }
  #Main #ContBox01 .errorBox ul.button li.return input{
      box-sizing:border-box;
      margin:0 auto;
      /*padding-top:12px;*/ 
      background-size:42px auto;
      width:290px;
      height:55px;
      font-weight:bold;
      text-decoration:none;
  }

  /*　確認画面　*/
  #Main .kakuninBox p.top{
      margin-bottom:15px;
      font-size:18px;
      font-weight:bold;
  }

  /*　完了画面　*/
  .thanks #Main #ContBox01 p.top a{
    width:290px;
    height:55px;
    font-weight:bold;
  }
  /*　#ContBox01
  -------------------------------------*/
  #PageContact #Main #ContBox01 h3{
    margin-bottom: 30px;
    padding: 30px 15px;
  }
  #PageContact #Main #ContBox01 h3 span{
    border-radius: 15px;
    padding-top: 17px;
    padding-bottom: 24px;
    font-size: 30px;
  }
  #PageContact #Main #ContBox01 h3 span b{
    position: relative;
    top: -3px;
    display: inline-block;
    margin: 0 auto;
    border-radius: 8px;
    padding: 6px 13px;
    font-size: 24px;
  }

  /*　#ContBox02
  -------------------------------------*/
  #PageContact #ContBox02 h3{
      font-size:24px;
  }

  #PageContact #ContBox02 p.top{
      font-size:17px;
  }

  #PageContact #ContBox02 .subbox{
      margin-bottom:11px;
  }

  #PageContact #Main #ContBox02 .subbox p:first-of-type{
    padding-top:15px;
  }

  #PageContact #Main #ContBox02 .subbox p,
  #PageContact #Main #ContBox02 .subbox ul li,
  #PageContact #Main #ContBox02 .subbox a{
      font-size:15px;
  }

  #PageContact #Main #ContBox02 .subbox p,
  #PageContact #Main #ContBox02 .subbox ul{
    padding:0 20px;
  }

  #PageContact #ContBox02 .subbox .title{
      height:50px;
      padding:13px 0;
  }

  /*　#ContBox03
  -------------------------------------*/
  #PageContact #ContBox03 .innerBasic{
    padding-bottom: 70px;
  }
  #PageContact #ContBox03 h3{
    margin-bottom: 23px;
    font-size: 30px;
  }
  #PageContact #ContBox03 td.list03 ul li label{
    margin-left: -3px;
  }
}


/*####################　PageBlog　####################*/
#PageBlog {
  #MainImgBox {
    background-color: #fffff1;
    border-bottom: 3px solid #f4f4cd;
    #MainImg {
      padding: 55px 0 83px;
      h2 {
        font-size: 50px;
        font-weight: bold;
        line-height: 1;
      }
    }
  }
  #Main {
    #ContBox01 {
      .navWrap {
        margin-bottom: 80px;
        .pageNav {
          display: flex;
          justify-content: center;
          li {
            margin-right: 20px;
            &:last-of-type { margin-right: 0; }
            a {
              box-sizing: border-box;
              display: block;
              width: 235px;
              font-size: 16px;
              font-weight: bold;
              background: url(../img/contents/arrow_04.png) no-repeat right 19px top 17px;
              background-size: 18px auto;
              padding: 14px 20px 14px 0;
              border: 2px solid #d9d9d9;
              border-radius: 40px;
              &:hover { text-decoration: none; }
            }
            &.current-cat a {
              background-image: url(../img/contents/arrow_03.png);
              color: #bbbbbb;
            }
          }
        }
      }
      .postList {
        .post {
          a { padding: 26px 0; }
          p { font-size: 18px; }
        }
      }
      .blogButton {
        margin: 60px auto 0;
        background-image: url(../img/contents/arrow_07.png);
      }
    }
  }
}

/*　カテゴリー　*/
#PageBlog.pageCategory {
  #Main {
    #ContBox01 {
      .catName {
        font-size: 50px;
        font-weight: bold;
        line-height: 70px;
        margin: -10px auto 48px;
      }
    }
  }
}

/*　記事詳細　*/
#PageBlog.pageEntry {
  #Main {
    #ContBox01 {
      .entry {
        .entryHead {
          h3 {
            font-weight: bold;
            letter-spacing: 0.05em;
            text-align: justify;
          }
          .info {
            display: flex;
            align-items: center;
            .date {
              font-size: 16px;
              color: #888;
              letter-spacing: 0.05em;
              margin-right: 26px;
            }
            .cat {
              width: 82px;
              font-size: 12px;
              color: #fcfa48;
              text-align: center;
              background-color: #0046b1;
              padding: 3px 0 2px;
            }
          }
        }
        .entryBox {
          margin: 40px 0 50px;
          border-top: 1px solid #e7e7e7;
          border-bottom: 1px solid #e7e7e7;
        }
      }
      .navWrap {
        &:last-of-type { margin-bottom: 0; }
        .postNav {
          width: 478px;
          margin: 0 auto;
          position: relative;
          text-align: center;
          li {
            a {
              display: block;
              width: 100%;
              font-size: 14px;
              color: #002f76;
              letter-spacing: 0.05em;
              line-height: 20px;
              box-sizing: border-box;
              &:hover { text-decoration: none; }
            }
            &.top {
              width: 200px;
              margin: 0 auto;
              a {
                border: 2px solid #d9d9d9;
                border-radius: 40px;
                padding: 8px 0;
              }
            }
            &.prev {
              position: absolute;
              top: 10px;
              left: 0;
              a {
                background: url(../img/contents/arrow_05.png) no-repeat left top;
                background-size: 11px auto;
                padding-left: 21px;
              }
            }
            &.next {
              position: absolute;
              top: 10px;
              right: 0;
              a {
                background: url(../img/contents/arrow_06.png) no-repeat right top;
                background-size: 11px auto;
                padding-right: 21px;
              }
            }
          }
        }
      }
    }
  }
}

@media print, screen and (min-width: 768px) {
  #PageBlog {
    #Main {
      #ContBox01 {
        margin-top: 50px;
        .navWrap {
          .pageNav {
            li {
              a:hover { opacity: 0.7; }
            }
          }
        }
      }
    }
  }

  #PageBlog.pageEntry {
    #Main {
      #ContBox01 {
        padding-bottom: 50px;
        .navWrap {
          margin-bottom: 65px;
          .postNav {
            li a:hover { opacity: 0.7; }
          }
        }
        .entry {
          .entryHead {
            h3 {
              font-size: 28px;
              line-height: 56px;
              margin-bottom: 5px;
            }
          }
          .entryBox {
            padding: 54px 0 73px;
            p {
              font-size: 18px;
              letter-spacing: 0.05em;
              line-height: 30px;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  #PageBlog {
    #MainImgBox {
      #MainImg {
        padding: 44px 0 63px;
        h2 {
          font-size: 30px;
          text-align: center;
        }
      }
    }
    #Main {
      #ContBox01 {
        margin-top: 30px;
        .navWrap {
          margin-bottom: 29px;
          .pageNav {
            flex-wrap: wrap;
            justify-content: space-between;
            li {
              width: calc( (100% - 10px) / 2 );
              margin-top: 10px;
              margin-right: 0;
              a {
                width: 100%;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                text-decoration: none;
                background-position: right 13px center;
                padding: 17px 0 15px;
              }
              &:first-of-type {
                width: 100%;
                margin-top: 0;
              }
            }
          }
        }
        .postList {
          .post {
            a { padding: 22px 0; }
            p { line-height: 26px; }
          }
        }
        .blogButton { margin-top: 40px; }
      }
    }
  }

  /*　カテゴリー　*/
  #PageBlog.pageCategory {
    #Main {
      #ContBox01 {
        .catName {
          font-size: 24px;
          line-height: 48px;
          margin: 0 auto 5px;
        }
      }
    }
  }

  /*　記事詳細　*/
  #PageBlog.pageEntry {
    #Main {
      #ContBox01 {
        .entry {
          .entryHead {
            h3 {
              font-size: 20px;
              line-height: 30px;
              margin-bottom: 8px;
            }
            .info {
              .date {
                margin-right: 8px;
              }
            }
          }
          .entryBox {
            margin: 20px 0 30px;
            padding: 28px 0 35px;
            p {
              font-size: 17px;
              line-height: 23px;
              margin-bottom: 10px;
            }
          }
        }
        .navWrap {
          margin-bottom: 35px;
          &:last-of-type { margin-bottom: 0; }
          .postNav {
            width: 237px;
            padding-top: 50px;
            li {
              &.top {
                a {
                  border: 2px solid #d9d9d9;
                  border-radius: 40px;
                  padding: 8px 0;
                }
              }
              &.prev {
                top: 0;
                a {
                  background: url(../img/contents/arrow_05.png) no-repeat left top;
                  background-size: 11px auto;
                  padding-left: 21px;
                }
              }
              &.next {
                top: 0;
                a {
                  background: url(../img/contents/arrow_06.png) no-repeat right top;
                  background-size: 11px auto;
                  padding-right: 21px;
                }
              }
            }
          }
        }
      }
    }
  }

}